<template>
  <div class="flex flex-row border border-2 border-gray-400 rounded w-full">
    <div class="has-tooltip-2 w-full p-2">
      <div class="w-fit whitespace-pre-line">
        {{ note.note }}
      </div>
      <span v-if="note.register_date"
        class='tooltip whitespace-pre-line'>
        {{ noteTooltip }}
      </span>
    </div>
  </div>
</template>

<script setup>
  import moment from 'moment-timezone';
  import { computed } from 'vue';

  const props = defineProps( {
    note: { Object, require: true },
  } );

  const noteTooltip = computed( () => {
    return props.note.register_date != null
      ? moment( props.note.register_date ).format( 'L à LT' )
        + ( props.note.author && props.note.author.fullname ? '\n' + props.note.author.fullname : '' )
      : '';
  } );
</script>