<template>
  <div class="border rounded-md shadow-xl bg-events
              text-center m-3 z-0
              aspect-square radius relative max-h-[10rem] max-w-[10rem]"
       :class="angle" :id="action.id"
       :id_action_user="action.id_action_user" :pilote="action.pilote"
       :deliveryDate="action.delivery_date" droppable="true" :cell="cell">
    <div droppable="true" :cell="cell" class="">
      <div v-if="action.full_action != null &&
        action.full_action.action_type != null ? action.full_action.action_type === 'capitalisation' : false"
        class="relative" droppable="true" :cell="cell">
          <div class="absolute top-0 left-0 flex justify-center py-[2px] pl-1 items-center"
            droppable="true" :cell="cell">
            <div class="flex justify-center items-center pr-3"
              droppable="true" :cell="cell">
              <img src="@/../public/img/capitalising_logo.png" class="h-4 w-4"
                droppable="true" :cell="cell">
            </div>
          </div>
      </div>
      <div class="rounded-t-md h-5 text-center flex flex-row items-center justify-center"
        :class="`bg-themes-${ ( action.theme % 20 ) + 1 }`" droppable="true" :cell="cell">
        <div class="rounded-full h-2 w-2 bg-red-100" droppable="true" :cell="cell"></div>
      </div>
      <div droppable="true" :cell="cell" class="break-words">
        {{ action.title }}
      </div>
      <div class="absolute bottom-0 right-0" droppable="true" :cell="cell">
        <div droppable="true" :cell="cell"
          class="flex flex-row justify-end gap-1 m-1 items-center">
          <div v-if="action.late && action.late > 0"
            class="py-0.5 px-1 w-min rounded bg-red-600 text-white text-xs font-bold" droppable="true" :cell="cell">
              {{ action.late }}
          </div>
          <div v-if="action.complete_date" droppable="true" :cell="cell">
            <font-awesome-icon icon="fa-solid fa-circle-check" size="lg" style="color: #45D52E;" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
  import { computed } from 'vue';
  defineProps( {
    action: { Object, required: true },
    cell: { String, required: true }
  } );
  const angle = computed( () => {
    let angle = Math.ceil( Math.random() * 5 ) - 3;
    if ( angle > 0 ) {
      return 'rotate-' + angle;
    } else {
      return '-rotate-' + -angle;
    }
  } );
</script>