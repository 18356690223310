<template>
  <div class="bg-white shadow shadow-shadowColor rounded-xl p-4">
    <div class="flex w-fit h-fit justify-center">
      <VChart class="chart"
        auto-resize
        :option="option"
        :autoresize="true"/>
    </div>
  </div>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';

  // Apache E-Charts mandatory modules
  import { use } from 'echarts/core';

  // Apache E-Charts manually imported modules
  import
  {
    DatasetComponent,
    TitleComponent,
    LegendComponent,
    TooltipComponent
  } from 'echarts/components';
  import { PieChart } from 'echarts/charts';
  import { LabelLayout } from 'echarts/features';
  import { SVGRenderer } from 'echarts/renderers';
  import { computed } from 'vue';

  const props = defineProps( {
    rp: { Object, required: true },
    data: { Array, required: true }
  } );

  const { t } = useI18n();

  var COLORS = Array( '#A6A6A6', '#37A2DA', '#45D52E' );

  // eslint-disable-next-line vue/no-setup-props-destructure
  let setupChartColorOrder = [
    { value: 0, name: 'State1', step: '' },
    { value: 0, name: 'State2', step: '' },
    { value: 0, name: 'State3', step: '' }
  ];

  const curChartData = computed( () => {
    return setupChartColorOrder.concat( props.data.map( ( e ) => { return e; } ) );
  } );

  curChartData.value.forEach( ( e ) => {
    switch ( e.name ) {
    case 'State1': e.name = t( 'To do' ); break;
    case 'State2': e.name = t( 'In progress' ); break;
    case 'State3': e.name = t( 'Done' ); break;
    }
    switch ( e.step ) {
    case 'Step1': e.step = t( 'Description of problem' ); break;
    case 'Step2': e.step = t( 'Securisation' ); break;
    case 'Step3': e.step = t( 'Causes' ); break;
    case 'Step4': e.step = t( 'Actions' ); break;
    case 'Step5': e.step = t( 'Capitalisation' ); break;
    }
  } );

  // E-Chart configuration
  use( [
    DatasetComponent,
    TitleComponent,
    LegendComponent,
    PieChart,
    SVGRenderer,
    LabelLayout,
    TooltipComponent
  ] );

  // eslint-disable-next-line vue/no-setup-props-destructure
  var option = {
    title: {
      text: t( 'Progress of RP n° ' ) + props.rp.number,
      left: 'center',
      bottom: '0%',
      textStyle: {
        fontSize: 18
      }
    },
    legend: {
      top: '10%',
      left: 'center',
      selectedMode: false,
      itemWidth: 30,
      itemHeight: 19,
      textStyle: {
        fontSize: 16
      }
    },
    dataset: {
      source: curChartData.value
    },
    series: {
      type: 'pie',
      id: 'ProgressPieChart',
      name: 'Progress Pie Chart',
      encode: {
        value: 0,
        itemName: 1
      },
      legendHoverLink: false,
      stillShowZeroSum: false,
      radius: [ '45%', '73%' ],
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 5
      },
      label: {
        show: false,
        position: 'center',
        formatter: '{@[2]}',
        fontSize: 12
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 16,
          fontWeight: 'bold'
        }
      },
      color: COLORS
    }
  };
</script>

<style scoped>
  .chart {
    height: 500px;
    width: 320px;
  }
</style>