<template>
  <div class="tex t-small flex flex-col space-y-1">
    <div class="w-full">
      <div class="grid grid-cols-2 text-sm">
        <div class="text-secondary-text font-bold">{{ t( 'Pilote' ) }}</div>
        <InputSelect
          :items="pilotes"
          :selected="rp.pilote.id"
          :readonly="readonly"
          @change-value="changePilote"
          :key="componentKey"/>
      </div>
    </div>
    <div class="w-full">
      <div class="grid grid-cols-2 text-sm">
        <div class="text-secondary-text font-bold">{{ t( 'Site' ) }}</div>
        <div :key="componentKey">{{ rp.site.name }}</div>
      </div>
    </div>
    <div class="w-f">
      <div class="grid grid-cols-2 text-sm">
        <div class="text-secondary-text font-bold">{{ t( 'Users' ) }}</div>
        <RPUsers
          :id= "rp.id"
          :all_users="pilotes"
          :users="rp.rp_users"
          :readonly="readonly"
          @changeUsers="changeUsers"
          :key="componentKey"/>
      </div>
    </div>
    <div class="w-f">
      <div class="grid grid-cols-2 text-sm">
        <div class="text-secondary-text font-bold">{{ t( 'Client name' ) }}</div>
        <FieldBasic :field="rp.client_name"
          :placeholder="readonly ? '' : t( 'Enter a client name' )"
          :fkey="RP_FIELD.CLIENT_NAME"
          :maxlength="MAX_INPUT_LENGTH.RP_CLIENT_NAME"
          :readonly="readonly"
          @change="changeClientName"/>
      </div>
    </div>
    <div class="w-f">
      <div class="grid grid-cols-2 text-sm">
        <div class="text-secondary-text font-bold">{{ t( 'Client number' ) }}</div>
        <FieldBasic :fkey="RP_FIELD.CLIENT_NUMBER"
          :placeholder="readonly ? '' : t( 'Enter a client number' )"
          :field="rp.client_number"
          :maxlength="MAX_INPUT_LENGTH.RP_CLIENT_NUMBER"
          :readonly="readonly"
          @change="changeClientNumber"/>
      </div>
    </div>
    <div class="w-f">
      <div class="grid grid-cols-2 text-sm">
        <div class="text-secondary-text font-bold">{{ t( 'Open' ) }}</div>
        <div :key="componentKey">{{ moment(rp.register_date).format('LL à LT') }}</div>
      </div>
    </div>
    <div class="w-f">
      <div class="grid grid-cols-2 text-sm">
        <div class="text-secondary-text font-bold">{{ t( 'Update' ) }}</div>
        <div :key="componentKey">{{ moment(rp.last_activity).format('LL à LT') }}</div>
      </div>
    </div>
    <div class="w-f text-sm">
      <FieldDateTime
        v-if="rp.detected_date"
        :title="t( 'Detected Date' )"
        :field="rp.detected_date"
        @change="changeDetectionDate"
        :readonly="readonly"
        :clearable="false"
        :maxDate="moment( rp.register_date ).toDate()"
        :key="componentKey"/>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import moment from 'moment-timezone';
  import InputSelect from '@/components/object/InputSelect.vue';
  import RPUsers from '@/components/rp/details/RPUsers.vue';
  import API from '@/constants/api.constants';
  import FieldDateTime from '@/components/object/FieldDateTime.vue';
  import FieldBasic from '@/components/object/FieldBasic.vue';
  import { RP_FIELD } from '@/constants/rp.constants.js';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const emit = defineEmits( [ 'changeItem', 'changeUsers' ] );
  const props = defineProps( {
    rp: { Object, required: true },
    readonly: { Boolean, required: false, default: false }
  } );

  const { t } = useI18n();
  const pilotes = ref( [] );

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };

  const changeItem = ( field ) => {
    emit( 'changeItem', field );
  };

  const changeUsers = () => {
    emit( 'changeUsers' );
  };

  const init = () => {
    let url = API.get_api( API.API_NAMES.RP_USERS_AUTH, props.rp.id );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '200' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          response.data.forEach( ( user ) => {
            pilotes.value.push( { val: user.id, text: user.fullname } );
          } );
        }
        forceRerender();
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  onMounted( init );

  const changePilote = ( val ) => {
    AXIOS.put( API.get_api( API.API_NAMES.RP, props.rp.id ), { pilote: val }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        changeItem( RP_FIELD.PILOT );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const changeClientName = ( key, val ) => {
    AXIOS.put( API.get_api( API.API_NAMES.RP, props.rp.id ), { client_name: val }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        changeItem( key );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const changeClientNumber = ( key, val ) => {
    AXIOS.put( API.get_api( API.API_NAMES.RP, props.rp.id ), { client_number: val }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        changeItem( key );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const changeDetectionDate = ( ...val ) => {
    if ( val[ 1 ] != null && val[ 1 ] != undefined ) {
      AXIOS.put( API.get_api( API.API_NAMES.RP, props.rp.id ), { detected_date: val[ 1 ] }, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeItem( RP_FIELD.DETECTED_DATE );
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          forceRerender();
          utils.showCatch( err );
        } );
    }
  };
</script>
