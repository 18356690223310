<template>
  <div class="flex-col justify-center space-y-6 min-w-[40rem]">
    <!-- Modal header -->
    <h1 class="text-2xl/5 font-bold text-center"> {{ t( 'Templates list' ) }} </h1>

    <!-- Modal Body -->
    <!-- Capitalising actions templates scroll list -->
    <div v-if="templatesCheckList" class="max-h-[400px] border-2 border-gray-400 rounded overflow-auto">
      <div v-for="template in templatesCheckList"
        :template="template"
        :key="template"
        :readonly="readonly"
        @click="check( template )"
        class="flex items-center pl-2 py-2 space-x-6 hover:bg-fourth-hover duration-200 border border-$c-divider border-gray-300"
        :class="!readonly ? 'clickable' : ''"
        >
        <input :id="template.id" type="checkbox"
          :checked="template.checked"
          class="w-6 h-6 accent-green-600/95 rounded"
          :class="!readonly ? 'clickable' : ''"
          :disabled="readonly">
        <p v-html="template.label"></p>
        <p><i v-html="template.label_tooltip"></i></p>
      </div>
    </div>
    <div v-else class="flex justify-center">
      <Spinner size="w-20"/>
    </div>

    <!-- Modal footer-->
    <div v-if="!readonly" class="flex space-x-4 justify-end items-center">
      <div>
        <LoadingButton :disabled="!canAdd" :label="t( 'Add' )" @click="save"/>
      </div>
      <div>
        <LoadingButton :label="t( 'Cancel' )" @click="close"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import settings from '@/features/settings';
  import AXIOS from '@/features/axios.js';
  import API from '@/constants/api.constants';
  import Spinner from '@/components/object/SpinnerBasic.vue';

  const props = defineProps( {
    readonly: { Boolean, required: false, default: true },
    id_rp: { String, required: true }
  } );

  const emit = defineEmits( [ 'close' ] );

  const { t } = useI18n();

  const templatesCheckList = ref( null );

  const canAdd = computed( () => {
    let res = false;
    if ( templatesCheckList.value != null
      && Array.isArray( templatesCheckList.value ) ) {
      templatesCheckList.value.every( ( item ) => {
        if ( item != null && item.checked === true ) {
          res = true;
          return false;
        }
        return true;
      } );
    }
    return res;
  } );

  onMounted( async () => {
    let templates = await settings.getOneSetting( 'priority_matrix_template' );
    templatesCheckList.value = templates.map( ( template ) => {
      template.checked = false;
      return template;
    } );
  } );

  const check = ( template ) => {
    if ( !props.readonly && template != null
      && template.checked != null ) {
      template.checked = !template.checked;
    }
  };

  const close = () => {
    emit( 'close', false );
  };

  const save = () => {
    let promises = [];

    if ( templatesCheckList.value != null
      && Array.isArray( templatesCheckList.value )
      && templatesCheckList.value.length ) {

      let url = API.get_api( API.API_NAMES.TOOL_PRIORITY_MATRIX );
      templatesCheckList.value.forEach( ( template ) => {
        if ( template.checked != null && ( template.checked === true || template.checked === 'true' ) ) {
          let body = {
            id_rp: props.id_rp,
            label: template.label ?? undefined,
            label_tooltip: template.label_tooltip ?? undefined
          };
          promises.push( new Promise ( ( resolve ) => AXIOS.post( url, body, { headers: { 'auth-token': store.getters.getToken } } )
            .then( ( response ) => {
              utils.showAxiosError( response );
              resolve();
            } )
            .catch( ( err ) => {
              utils.showCatch( err );
              resolve();
            } ) ) );
        }
      } );
    }
    Promise.all( promises )
      .then( () => {
        promises.length <= 0 ? emit( 'close', false ) : emit( 'close', true );
      } );
  };
</script>