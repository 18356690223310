<template>
  <div @click="click"
    class="flex items-center pl-2 space-x-6 py-1 hover:bg-fourth-hover duration-200 border border-$c-divider border-gray-300"
    :class="!readonly ? 'cursor-pointer' : ''">
    <input :id="capitalisingAction.id" type="checkbox"
      :checked="capitalisingAction.checked"
      class="w-6 h-6 accent-green-600/95 rounded"
      :class="!readonly ? 'cursor-pointer' : ''"
      :disabled="readonly">
    <div class="w-fit flex flex-row space-x-3 items-center">
      <div class="pt-1 pb-2">
        <FieldArea :field="capitalisingAction.title" :readonly="true"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import FieldArea from '@/components/object/FieldArea.vue';

  const props = defineProps( {
    capitalisingAction: { Object, require: true },
    readonly: { Boolean, required: false, default: true }
  } );

  const emit = defineEmits( [ 'click' ] );

  const click = () => {
    emit( 'click', props.capitalisingAction );
  };
</script>