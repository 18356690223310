<template>
  <div class="text-sm bg-white shadow shadow-shadowColor rounded focus-visible:outline-none mb-4 p-4">
    <span class="text-xs font-semibold mr-2 px-2.5 py-0.5 h-5 rounded"
      :class="rp.state != null && rp.state === 'open' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800 '">
      {{ t( rp.state ) }}
    </span>
    <br>
    <div class="text-2xl mb-0">
      <p>{{ rpNumberText }}</p>
      <div class="text-4xl font-bold">
        <Field :field="rp.title" :fkey="rp.id"
          :placeholder="t( 'Title PLACEHOLDER' )"
          :maxlength="MAX_INPUT_LENGTH.RP_TITLE"
          :readonly="readonly"
          @change="changeTitle"
          :key="componentKey"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import Field from '@/components/object/FieldBasic.vue';
  import { RP_FIELD } from '@/constants/rp.constants.js';
  import API from '@/constants/api.constants';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    rp: { Object, required: true },
    readonly: { Boolean, required: false, default: false }
  } );
  const emit  = defineEmits( [ 'changeItem' ] );

  const { t } = useI18n();
  const editable = ref ( false );

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };

  const rpNumberText = computed( () => {
    return t( 'RP Number' ) + ' ' + props.rp.number;
  } );

  const changeItem = ( ...fields ) => {
    emit( 'changeItem', fields );
  };

  const changeTitle = ( fkey, title ) => {
    AXIOS.put( API.get_api( API.API_NAMES.RP, fkey ), { title }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        changeItem( RP_FIELD.TITLE );
        editable.value = false;
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };
</script>