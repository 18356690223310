<template>
  <IconEditButton :css="'hover:bg-primary-hover'"
    :disableLoading="disableLoading" tooltipContent="Validate"
    ref="actionBtn" icon="fa-solid fa-check"/>
</template>

<script setup>
  import { ref } from 'vue';
  import IconEditButton from '@/components/buttons/IconEditButton';

  defineProps( {
    disableLoading: { Boolean, default: false }
  } );

  const actionBtn = ref( null );

  const startLoading = () => { actionBtn.value.startLoading(); };
  const stopLoading = () => { actionBtn.value.stopLoading(); };

  defineExpose( { startLoading, stopLoading } );
</script>