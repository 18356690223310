<template>
  <div class="flex h-full w-full place-content-center">
    <Spinner v-if="!formattedData.xAxisData || !formattedData.yAxisData" size="w-40"/>
    <VChart v-else class="flex-none"
      :option="option"
      ref="echart"
      :autoresize="false"
      @click="clickCause"/>
  </div>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';
  import { ref, onMounted, computed } from 'vue';

  // Apache E-Charts mandatory modules
  import { use } from 'echarts/core';

  // Apache E-Charts manually imported modules
  import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
  } from 'echarts/components';
  import { BarChart } from 'echarts/charts';
  import { UniversalTransition } from 'echarts/features';
  import { SVGRenderer } from 'echarts/renderers';
  import AXIOS from '@/features/axios.js';
  import utils from '@/features/utils.js';
  import API from '@/constants/api.constants.js';
  import store from '@/store';
  import Spinner from '@/components/object/SpinnerBasic.vue';

  use( [
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    SVGRenderer,
    UniversalTransition
  ] );

  const MAX_LABEL_LENGTH = 35;

  const props = defineProps( {
    id_rp: { String, required: true }
  } );
  const emit = defineEmits( [ 'clickCause' ] );

  onMounted( () => {
    let url = API.get_api( API.API_NAMES.TOOL_PRIORITY_MATRIX, props.id_rp, 'pareto' );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        utils.showAxiosError( response );
        sortedData.value = response.data.sort( ( item1, item2 ) => {
          if ( item1.total != null && item2.total ) {
            return item2.total - item1.total;
          }
          return 0;
        } );
        formatData( sortedData.value );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  } );

  const { t } = useI18n();

  const option = computed( () => {
    return {
      tooltip: {
        trigger: 'axis'
      },
      grid: [
        { height: '60%', width: '90%', left: '7%' }
      ],
      legend: {
        top: '0%',
        left: 'center',
        selectedMode: false,
        itemWidth: 28,
        itemHeight: 17,
        textStyle: {
          fontSize: 16
        }
      },
      xAxis: {
        type: 'category',
        data: formattedData.value.xAxisData,
        gridIndex: 0,
        axisPointer: {
          type: 'shadow'
        },
        axisLabel: {
          interval: 0,
          rotate: 60,
          fontSize: 13,
          formatter: ( value ) => {
            if ( value.length > MAX_LABEL_LENGTH ) {
              return value.slice( 0, MAX_LABEL_LENGTH ) + '...';
            }
            return value;
          }
        }
      },
      yAxis: {
        type: 'value',
        name: t( 'Weight' ),
        gridIndex: 0,
        nameTextStyle: {
          fontSize: 15
        },
        min: 0,
        interval: 20,
        axisLabel: {
          formatter: '{value}',
          fontSize: 15
        }
      },
      series: [
        {
          name: t( 'Weight' ),
          color: '#546fc6',
          type: 'bar',
          data: formattedData.value.yAxisData
        }
      ]
    };
  } );

  const sortedData = ref( [] );
  const formattedData = ref( {} );

  const clickCause = ( event ) => {
    if ( event != null
      && event.dataIndex != null
      && sortedData.value != null
      && Array.isArray( sortedData.value )
      && sortedData.value[ event.dataIndex ].id_cause ) {
      emit( 'clickCause', sortedData.value[ event.dataIndex ].id_cause );
    }
  };

  const formatData = ( data ) => {
    if ( data != null && Array.isArray( data ) ) {

      let formattedAxis = {
        xAxisData: [],
        yAxisData: []
      };

      data.forEach( ( item ) => {
        if ( item.total != null
          && item.id_cause != null
          && item.description != null ) {
          formattedAxis.xAxisData.push( item.description );
          formattedAxis.yAxisData.push( item.total );
        }
      } );
      formattedData.value = formattedAxis;
    }
  };

</script>