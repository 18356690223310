<template>
  <div>
    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="flex-col space-y-6">
      <!-- Modal header -->
      <h1 class="text-2xl/5 font-bold text-center">
        {{ t( 'Add a criterion' ) }}
      </h1>

      <!-- Modal Body -->
      <div class="flex-col space-y-4">
        <div class="flex items-center">
          <span class="text-secondary-text font-bold pr-2">{{ t( 'Weight' ) }}</span>
          <div v-for="box in DEFAULT_WEIGHT_VALUES" :key="box.weight"
            :class="weight === box.weight ? ' bg-blue-400' : 'transition duration-200 hover:bg-fourth-hover'"
            class="flex text-xl rounded-sm w-8 aspect-square font-bold justify-center items-center"
            @click="weight = box.weight">
            {{ box.weight }}
          </div>

          <span class="text-secondary-text font-bold pl-10 pr-2">{{ t( 'Ascending weight' ) }}</span>
          <input v-model="is_ascending"
            type="checkbox"
            class="w-6 h-6 accent-green-600/95 rounded"
            :class="!readonly ? 'cursor-pointer' : ''"
            :disabled="readonly">
        </div>

        <FieldBasic :readonly="readonly"
          :field="labels.label"
          :placeholder="t( 'Criterion name' )"
          :title="t( 'Criterion name' )"
          fkey="label"
          @change="changeField"
          :maxlength="MAX_INPUT_LENGTH.TOOL_PRIORITY_MATRIX_LABEL"/>

        <FieldArea :readonly="readonly"
          :field="labels.label_tooltip"
          :placeholder="t( 'Criterion description' )"
          fkey="label_tooltip"
          :title="t( 'Criterion description' )"
          @change="changeField"
          :maxlength="MAX_INPUT_LENGTH.TOOL_PRIORITY_MATRIX_LABEL_TOOLTIP"/>
      </div>

      <!-- Modal footer-->
      <div v-if="!readonly" class="flex justify-end">
        <div>
          <LoadingButton type="submit"
            :label="t( 'Add' )"
            @click="add"
            ref="addBtn"
            :disabled="!canSubmit"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import FieldBasic from '@/components/object/FieldBasic.vue';
  import FieldArea from '@/components/object/FieldArea.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    readonly: { Boolean, required: false, default: false },
    isAddable: { Boolean, required: true, default: false }
  } );

  const emit = defineEmits( [ 'close', 'add' ] );

  const { t } = useI18n();

  const addBtn = ref();

  const canSubmit = computed( () => {
    return props.isAddable
      && labels.value.label != null
      && labels.value.label != '';
  } );

  const DEFAULT_WEIGHT_VALUES = [
    { weight: '1', color: 'green-400' },
    { weight: '3', color: 'yellow-400' },
    { weight: '7', color: 'orange-400' },
    { weight: '10', color: 'blue-400' },
  ];

  const weight = ref( null );

  const is_ascending = defineModel( { default: true } );

  const labels = ref( {
    label: null,
    label_tooltip: null
  } );

  const changeField = ( ...params ) => {
    if ( params != null
      && Array.isArray( params )
      && params.length >= 2 ) {
      labels.value[ params[ 0 ] ] = params[ 1 ];
    }
  };

  const clickClose = () => {
    emit( 'close' );
  };

  const add = () => {
    let body = {
      weight: weight.value ?? undefined,
      label: labels.value.label ?? undefined,
      label_tooltip: labels.value.label_tooltip ?? undefined,
      is_ascending: is_ascending.value
    };
    emit( 'add', body );
  };
</script>