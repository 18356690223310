<template>
  <div>
    <form>
      <div>
        <label class="block mb-2 text-blue-700" for="email">{{ t('Email') }}</label>
        <input class="w-full p-2 mb-3 text-blue-700 border-b-2 border-blue-500 outline-none focus:bg-gray-100" type="text" name="email" v-model="input.email">
      </div>
      <div>
        <label class="block mb-2 text-blue-700" for="password">{{ t('Password') }}</label>
        <input class="w-full p-2 mb-9 text-blue-700 border-b-2 border-blue-500 outline-none focus:bg-gray-100" type="password" name="password" v-model="input.password">
      </div>
      <div>
        <LoadingButton type="submit" ref="signInBtn" :label="t('Sign-in')" v-on:click="login()"/>
      </div>
      <div class="mt-3">
        <LoadingButton v-if="is_auth_flashmeeting" :label="t('Connect with Flash Meeting')" v-on:click="goToFlashmeetingLogin()"/>
      </div>
      <div class="mt-4 text-white grid grid-cols-2 justify-items-stretch">
        <div class="justify-self-start">
          <SelectLanguage/>
        </div>
        <div class="justify-self-end">
          <a class="text-blue-500 hover:text-blue-900 text-sm" href="#" v-on:click="clickForgotPasswordLink()">{{ t('Forgot Password?') }}</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
  import { ref, inject } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import router from '@/router';
  import store from '@/store';
  import AXIOS from '@/features/axios.js';
  import * as emailValidator from 'email-validator';
  import enumDisplayComponent from '@/enum/enumDisplayComponent';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import API from '@/constants/api.constants';
  import SelectLanguage from '@/components/object/SelectLanguage.vue';

  const emit = defineEmits( [ 'custom-event-name' ] );

  const { t } = useI18n();
  const input = ref( { email: '', password: '' } );
  const is_auth_flashmeeting = ref( false );
  const flashmeeting_url = ref( '' );
  const cryptojs = inject( 'cryptojs' );
  const signInBtn = ref( null );

  isAuthFlashmeetingConnect();

  function isAuthFlashmeetingConnect() {
    AXIOS.get( API.get_api( API.API_NAMES.AUTH_FM_IS_CONNECT ) )
      .then( ( response ) => {
        if ( response.status === 200 ) {
          flashmeeting_url.value = response.data.url;
          is_auth_flashmeeting.value = true;
        } else {
          is_auth_flashmeeting.value = false;
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  }

  const clickForgotPasswordLink = () => {
    emit( 'custom-event-name', { display: enumDisplayComponent.GET_CODE } );
  };

  const goToFlashmeetingLogin = () => {
    window.location = store.getters.getAuthFlashmeeting.url;
  };

  const login = () => {
    if ( input.value.email != '' && input.value.password != '' ) {
      if ( emailValidator.validate( input.value.email ) ) {
        AXIOS.post( API.get_api( API.API_NAMES.AUTH_SIGN_IN ), { email: input.value.email, password: cryptojs.HmacSHA1( input.value.password, process.env.VUE_APP_SECRET_PASSWORD ).toString() } )
          .then( ( response ) => {
            if ( response.status == '200' ) {
              AXIOS.get( API.get_api( API.API_NAMES.USERS, response.data.id ), { headers: { 'auth-token': response.headers[ 'auth-token' ] } } )
                .then( ( user ) => {
                  store.dispatch( 'setConnected', { connect: true, connect_user: user.data, token: user.headers[ 'auth-token' ] } );
                  router.push( '/dashboard' );
                } )
                .catch( ( err ) => {
                  utils.showCatch( err );
                  signInBtn.value.stopLoading();
                } );
            } else {
              signInBtn.value.stopLoading();
            }
            utils.showAxiosError( response );
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
            signInBtn.value.stopLoading();
          } );
      } else {
        utils.showWarning( t( 'Please enter a valid email address' ) );
        signInBtn.value.stopLoading();
      }
    } else {
      utils.showWarning( t( 'Please enter a valid email address and password' ) );
      signInBtn.value.stopLoading();
    }
  };

  // if ( 'development' === process.env.NODE_ENV ) {
  //   AXIOS.post( API.get_api( API.API_NAMES.AUTH_SIGN_IN ), { email: 'administrator@prod-eo.com', password: '4a361cb22ff3b13c27b11a60cb2e5abfed4639a0' } )
  //     .then( ( response ) => {
  //       if ( response.status == '200' ) {
  //         store.dispatch( 'setConnected', { connect: true, connect_id: response.data.id, token: response.headers[ 'auth-token' ] } );
  //         router.push( '/dashboard' );
  //       } else {
  //         // signInBtn.value.stopLoading();
  //       }
  //       utils.showAxiosError( response );
  //     } )
  //     .catch( ( err ) => {
  //       utils.showCatch( err );
  //       // signInBtn.value.stopLoading();
  //     } );
  // }
</script>