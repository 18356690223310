<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-sm p-0 relative"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">

    <img src="@/../public/img/congrats.gif" class="z-0 w-full h-full">

    <!-- Close modal button -->
    <div class="absolute top-0 right-0 z-10">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <p class="absolute inset-0 pt-[20%] text-center text-5xl font-bold z-0">
      {{ t( 'Congratulations' ) }}
    </p>

    <!-- Modal footer-->
    <div class="absolute z-10 bottom-0 right-0 flex space-x-4 justify-end p-2">
      <div>
        <LoadingButton type="submit" :label="t( 'Go to home page' )" @click="goHome"/>
      </div>
      <div>
        <LoadingButton type="submit" :label="t( 'Stay here' )" @click="clickClose"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';
  import { VueFinalModal } from 'vue-final-modal';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';

  defineProps( {
    escToClose: { Boolean, required: false, default: false },
    clickToClose: { Boolean, required: false, default: false },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false },
  } );
  const emit = defineEmits( [ 'goHome', 'cancel' ] );

  const { t } = useI18n();

  const goHome = () => {
    emit( 'goHome' );
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>