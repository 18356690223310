<template>
  <!-- Ishikawa drag & drop areas -->
  <div class="relative grow mt-4 mb-4">
    <div class="absolute flex flex-wrap justify-center space-x-[1%] gap-y-[1%] -ml-[2%] h-full w-full">
      <!-- Top areas -->
      <div v-for="(ishikawa_7m, idx) in evenIdx7mList(ishikawa_7m)"
        :key="ishikawa_7m"
        class="flex flex-col w-[20%] h-[50%] items-start"
        @drop="drop" :idx="evenIdx( idx )"
        droppable="true">
        <p class="text-2xl/7 w-full truncate shrink-0"
          :idx="evenIdx( idx )"
          droppable="true">
          {{ ishikawa_7m }}
        </p>
        <!-- Causes list -->
        <div class="flex flex-wrap w-full overflow-y-auto overflow-x-hidden "
          :idx="evenIdx( idx )"
          droppable="true">
          <div v-for="ishikawa_7m_cause in getIshiKawaListBy7M( evenIdx( idx ) )"
            :key="ishikawa_7m_cause"
            :idx="evenIdx( idx )"
            droppable="true"
            class="aspect-square w-[31%] h-fit mr-[1.5%] mb-[1.5%]"
            @click="emit( 'clickItem', ishikawa_7m_cause.id )">
            <img v-if="ishikawa_7m_cause.path != null && ishikawa_7m_cause.path !== ''"
              class="aspect-square h-full"
              :idx="evenIdx( idx )"
              droppable="true"
              :id_cause="ishikawa_7m_cause.id"
              :src="utils.causeImgPath( ishikawa_7m_cause.path )"
              :draggable="true"
              @dragstart="dragStart"/>
            <div v-else droppable="true" :idx="evenIdx( idx )"
              class="bg-yellow-300 border border-black rounded-sm w-full h-full px-1 pt-0.5 text-lg/[18px] break-words overflow-hidden"
              :draggable="true"
              @dragstart="dragStart"
              :id_cause="ishikawa_7m_cause.id">
              {{ ishikawa_7m_cause.description }}
            </div>
          </div>
        </div>
      </div>
      <!-- Bottom areas -->
      <div v-for="(ishikawa_7m, idx) in oddIdx7mList(ishikawa_7m)"
        :key="ishikawa_7m"
        class="flex flex-col w-[25%] h-[49%] justify-end"
        @drop="drop"
        :idx="oddIdx( idx )"
        droppable="true">
        <!-- Causes list -->
        <div class="flex flex-wrap-reverse w-[80%] overflow-y-auto overflow-x-hidden"
          :idx="oddIdx( idx )"
          droppable="true">
          <div v-for="ishikawa_7m_cause in getIshiKawaListBy7M( oddIdx( idx ) )"
            :key="ishikawa_7m_cause"
            :idx="oddIdx( idx )"
            droppable="true"
            class="aspect-square mr-[1.5%] mt-[1.2%] w-[31%] h-fit"
            @click="emit( 'clickItem', ishikawa_7m_cause.id )">
            <img v-if="ishikawa_7m_cause.path != null && ishikawa_7m_cause.path !== ''"
              class="aspect-square h-full"
              :idx="oddIdx( idx )"
              droppable="true"
              :id_cause="ishikawa_7m_cause.id"
              :src="utils.causeImgPath( ishikawa_7m_cause.path )"
              :draggable="true"
              @dragstart="dragStart"/>
            <div v-else droppable="true"
              :idx="oddIdx( idx )"
              class="bg-yellow-300 border border-black rounded-sm w-full h-full px-1 pt-0.5 text-lg/[18px] break-words overflow-hidden"
              :draggable="true"
              @dragstart="dragStart"
              :id_cause="ishikawa_7m_cause.id">
              {{ ishikawa_7m_cause.description }}
            </div>
          </div>
        </div>
        <p class="text-2xl/6 w-full truncate shrink-0"
          :idx="oddIdx( idx )"
          droppable="true">
          {{ ishikawa_7m }}
        </p>
      </div>
    </div>
    <!-- Ishikawa fishbone diagram SVG -->
    <img src="@/../public/img/Ishikawa_test_2.svg" alt="Ishikawa fishbone diagram">
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import API from '@/constants/api.constants';
  import utils from '@/features/utils.js';
  import settings from '@/features/settings';

  const ishikawa_7m = ref( [] );

  const emit = defineEmits( [ 'changeItem', 'clickItem' ] );

  const props = defineProps( {
    id_rp: { String, required: true },
    causes: { Array, required: true, default: [] }
  } );

  onMounted( async () => {
    ishikawa_7m.value = await settings.getOneSetting( 'ishikawa_7m' );
  } );

  const getIshiKawaListBy7M = ( category ) => {
    if ( category <= ishikawa_7m.value.length ) {
      return props.causes.filter( ( cause ) => {
        if ( cause.category == category ) {
          return true;
        }
      } );
    }
    return [];
  };

  const drop = ( event ) => {
    if ( event.target.getAttribute( 'droppable' ) == 'true' ) {
      let id_cause = event.dataTransfer.getData( 'id_cause' );
      let index_ishikawa = event.target.getAttribute( 'idx' );
      let original_index = event.dataTransfer.getData( 'original_idx' );

      if ( index_ishikawa != null &&
        id_cause != null &&
        original_index != null &&
        original_index != index_ishikawa ) {
        let url = API.get_api( API.API_NAMES.CAUSES, id_cause );
        AXIOS.put( url, { category: index_ishikawa }, { headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            if ( response.status == '201' ) {
              store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
              emit( 'changeItem', id_cause );
            }
            utils.showAxiosError( response );
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
          } );
      }
    }
  };

  const dragStart = ( event ) => {
    event.dataTransfer.setData( 'id_cause', event.target.getAttribute( 'id_cause' ) );
    event.dataTransfer.setData( 'original_idx', event.target.getAttribute( 'idx' ) );
  };

  const evenIdx7mList = ( list ) => {
    return list.filter( ( element ) =>
      list.indexOf( element ) % 2 == 0
    );
  };

  const oddIdx7mList = ( list ) => {
    return list.filter( ( element ) =>
      list.indexOf( element ) % 2 != 0
    );
  };

  const evenIdx = ( idx ) => {
    return idx * 2 + 1;
  };

  const oddIdx = ( idx ) => {
    return idx * 2 + 2;
  };
</script>