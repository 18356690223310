<template>
    <div class="flex flex-row items-center grow justify-end text-sm space-x-2 pl-14">
      <div class="has-tooltip flex">
        <SortAlphabeticalVariantIcon></SortAlphabeticalVariantIcon>
        <span class='tooltip'>{{ t( 'Sort' )}}</span>
      </div>
      <div class="text-secondary-text">{{ ' : ' }}</div>
      <div @click.stop class="w-fit">
        <InputSelect :items="sortOptions" :selected="sortSelected" @changeValue="changeSortOption"></InputSelect>
      </div>
      <div class="has-tooltip flex">
        <FilterOutlineIcon></FilterOutlineIcon>
        <span class='tooltip'>{{ t( 'Filter' )}}</span>
      </div>
      <div class="text-secondary-text font-bold">{{" : "}}</div>
      <div @click.stop class="w-fit">
        <InputSelect :items="filterOptions" :selected="filterSelected" @changeValue="changeFilterOption"></InputSelect>
      </div>
    </div>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';
  import InputSelect from '@/components/object/InputSelect.vue';
  import SortAlphabeticalVariantIcon from 'vue-material-design-icons/SortAlphabeticalVariant.vue';
  import FilterOutlineIcon from 'vue-material-design-icons/FilterOutline.vue';

  defineProps( {
    sortOptions: { Array, required: true, default: [] },
    sortSelected: { String, required: true, default: '' },
    filterOptions: { Array, required: true, default: [] },
    filterSelected: { String, required: true, default: '' }
  } );
  const emit = defineEmits( [ 'changeSortOption', 'changeFilterOption', 'cancel' ] );

  const { t } = useI18n();

  const changeSortOption = ( newOption ) => {
    emit( 'changeSortOption', newOption );
  };

  const changeFilterOption = ( newOption ) => {
    emit( 'changeFilterOption', newOption );
  };
</script>