<template>
  <div>
    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="flex-col space-y-6">
      <!-- Modal header -->
      <h1 class="text-2xl/5 font-bold text-center">
        {{ t( 'Modify a criterion' ) }}
      </h1>

      <!-- Modal Body -->
      <div class="flex-col space-y-4">
        <div class="flex items-center">
          <span class="text-secondary-text font-bold pr-2">{{ t( 'Weight' ) }}</span>
          <div v-for="box in DEFAULT_WEIGHT_VALUES" :key="box.weight"
            :class="column.weight == box.weight ? ' bg-blue-400' : 'transition duration-200 hover:bg-fourth-hover'"
            class="flex text-xl rounded-sm w-8 aspect-square font-bold justify-center items-center"
            @click="column.weight != box.weight ? changeField( 'weight', box.weight ) : undefined">
            {{ box.weight }}
          </div>

          <span class="text-secondary-text font-bold pl-10 pr-2">{{ t( 'Ascending weight' ) }}</span>
          <input type="checkbox"
            @click="changeField( 'is_ascending', column.is_ascending == 1 ? false : true )"
            :checked="column.is_ascending === 1"
            class="w-6 h-6 accent-green-600/95 rounded"
            :class="!readonly ? 'cursor-pointer' : ''"
            :disabled="readonly">
        </div>


        <FieldBasic :readonly="readonly"
          :field="column.label"
          :placeholder="t( 'Criterion name' )"
          :title="t( 'Criterion name' )"
          fkey="label"
          :maxlength="MAX_INPUT_LENGTH.TOOL_PRIORITY_MATRIX_LABEL"
          @change="changeField"/>
        <FieldArea :readonly="readonly"
          :field="column.label_tooltip"
          :placeholder="t( 'Criterion description' )"
          fkey="label_tooltip"
          :title="t( 'Criterion description' )"
          :maxlength="MAX_INPUT_LENGTH.TOOL_PRIORITY_MATRIX_LABEL_TOOLTIP"
          @change="changeField"/>
      </div>

      <!-- Modal footer-->
      <div v-if="!readonly" class="flex justify-end">
        <div>
          <LoadingButton type="submit"
            :label="t('Delete')"
            @click="remove" ref="deleteBtn"
            :disabled="!isDeletable"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import FieldBasic from '@/components/object/FieldBasic.vue';
  import FieldArea from '@/components/object/FieldArea.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  defineProps( {
    column: { Object, required: true, default: null },
    readonly: { Boolean, required: false, default: false },
    isDeletable: { Boolean, required: true, default: false }
  } );

  const emit = defineEmits( [ 'close', 'changeField', 'remove' ] );

  const { t } = useI18n();

  const deleteBtn = ref();

  const DEFAULT_WEIGHT_VALUES = [
    { weight: '1', color: 'green-400' },
    { weight: '3', color: 'yellow-400' },
    { weight: '7', color: 'orange-400' },
    { weight: '10', color: 'blue-400' },
  ];

  const remove = () => {
    emit( 'remove' );
  };

  const clickClose = () => {
    emit( 'close' );
  };

  const changeField = ( fkey, val ) => {
    emit( 'changeField', fkey, val );
  };
</script>