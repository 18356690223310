<template>
  <div class="flex border-b border-gray-200 dark:border-gray-700 justify-center">
    <ul class="flex flex-wrap -mb-px text-sm  font-bold text-center text-gray-500 dark:text-gray-400 mt-2">
        <li class="me-2" @click="() => tab_selected = TAB_ITEMS.USERS">
          <a class="item-tab-bar"
            :class="tab_selected == TAB_ITEMS.USERS ? 'text-primary border-primary' : ''">
            <font-awesome-icon class="fa-l" icon="fa-solid fa-users" />
            &nbsp; {{ t( 'Users_bis' ) }}
          </a>
        </li>
        <li class="me-2" @click="() => tab_selected = TAB_ITEMS.SERVICES">
            <a class="item-tab-bar"
              :class="tab_selected == TAB_ITEMS.SERVICES ? 'text-primary border-primary' : ''">
              <font-awesome-icon class="fa-l" icon="fa-solid fa-id-badge" />
              &nbsp; {{ t( 'Services' ) }}
            </a>
        </li>
        <!-- Décommenter quand on affichera les sites
        <li class="me-2" @click="() => tab_selected = TAB_ITEMS.SITES">
            <a class="item-tab-bar" :class="tab_selected == TAB_ITEMS.SITES ? 'text-primary border-primary' : ''"> <font-awesome-icon class="fa-l" icon="fa-solid fa-building" />&nbsp; {{ t( 'Sites' ) }} </a>
        </li>
        -->
    </ul>
  </div>
  <div class="flex h-full w-full justify-center">
    <div class="h-full w-3/5 min-w-[50rem]">
      <UsersComponent class="h-full w-full" v-if="tab_selected == TAB_ITEMS.USERS"/>
      <ServicesComponent class="h-full w-full" v-if="tab_selected == TAB_ITEMS.SERVICES"/>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import UsersComponent from '@/components/management/UsersComponent.vue';
  import ServicesComponent from '@/components/management/ServicesComponent.vue';

  const { t } = useI18n();
  const TAB_ITEMS = {
    USERS: 'users',
    SERVICES: 'services',
    SITES: 'sites',
  };
  const tab_selected = ref( TAB_ITEMS.USERS );
</script>

<style lang="scss">
  .item-tab-bar {
    @apply inline-flex;
    @apply items-center;
    @apply justify-center;
    @apply p-4;
    @apply border-b-2;
    @apply border-transparent;
    @apply rounded-t-lg;
    @apply hover:text-primary;
    @apply dark:hover:text-primary;
    @apply cursor-pointer;
  }
</style>
