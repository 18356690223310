<template>
  <div class="text-center" :id="user.id">
    <img class="h-auto w-auto p-4" src="@/../public/img/no_user_logo.jpeg" />
    <span>{{ fullname }}</span>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps( {
    user: { Object, required: true }
  } );

  const fullname = computed( () => {
    return props.user.firstname + ' ' + props.user.lastname;
  } );
</script>