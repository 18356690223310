<template>
  <div class="text-center bg-gray-300">
    <div class="text-xs font-base text-black-500 py-2">
      {{ footerCopyrightText }}
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import Constants from '@/constants/constants.js';

  const { t } = useI18n();

  const footerCopyrightText = computed( () => {
    return Constants.app.name + ' '
      + Constants.version.toString
      + ' - ' + t( 'Copyright' ) + ' - '
      +  t( 'All Right Reserved' );
  } );
</script>