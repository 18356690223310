<template>
  <div v-if="rounded" class="has-tooltip bg-white rounded-full shadow shadow-shadowColor focus-visible:outline-none hover:bg-tertiary-hover"
    @click="add">
    <span v-if="tooltipContent && tooltipContent != ''" class='tooltip'>
      {{ tooltipContent }}
    </span>
    <button class="rounded-full text-primary w-full h-full text-3xl">
      <font-awesome-icon icon="fa-plus"/>
    </button>
  </div>
  <div v-else class="has-tooltip bg-white shadow shadow-shadowColor focus-visible:outline-none hover:bg-tertiary-hover"
    @click="add">
    <span v-if="tooltipContent && tooltipContent != ''" class='tooltip'>
      {{ tooltipContent }}
    </span>
    <button class="text-primary w-full h-full text-3xl">
      <font-awesome-icon icon="fa-plus"/>
    </button>
  </div>
</template>

<script setup>
  defineProps( {
    tooltipContent: { String, require: false, default: null },
    rounded: { Boolean, require: false, default: false }
  } );

  const emit = defineEmits( [ 'add' ] );

  const add = () => {
    emit( 'add' );
  };
</script>