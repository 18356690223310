<template>
  <div class="text-secondary-text font-bold text-sm" v-html="title"></div>
  <div :class="css_class == '--dp-small' ? 'w-[170px]' : 'w-fit'">
    <VueDatePicker v-model="fieldDate" :enable-time-picker="false" range
      :input-class-name="css_class"
      :locale="params.locale" :format="params.format"
      :key="componentKeyInit" @update:modelValue="handleDate"
      :readonly="readonly"
      @cleared="clearedDate"
      :partial-range="false"
      :min-date="minDate" :max-date="maxDate"
      :clearable="clearable"
      :placeholder="datePlaceholder"
      :preset-dates="presetDates"
      ref="datePicker">
      <template #input-icon>
        <font-awesome-icon class="ml-2" :size="css_class === '--dp-small' ? 'sm' : 'lg'" icon="fa-solid fa-calendar" />
      </template>
      <template #action-buttons>
        <div class="flex space-x-2 items-center">
          <button class="dp-cancel-btn" @click="closeMenu">{{ t('datetime-picker-cancel') }}</button>
          <button class="dp-select-btn" @click="selectDate">{{ t( 'datetime-picker-select' ) }}</button>
        </div>
      </template>
      <template v-if="presetDates.length > 0" #preset-date-range-button="{ label, value, presetDate }">
        <span role="button" :tabindex="0" @click="presetDate(value)" @keyup.enter.prevent="presetDate(value)" @keyup.space.prevent="presetDate(value)">
          {{ label }}
        </span>
      </template>
    </VueDatePicker>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import store from '@/store';
  import moment from 'moment';
  import VueDatePicker from '@vuepic/vue-datepicker';

  const props = defineProps( {
    title: { String, required: false, default: '' },
    start_date: { String, required: true },
    end_date: { String, required: true },
    rkey: { Object, required: false },
    readonly: { Boolean, required: false, default: false },
    minDate: { Date, required: false, default: null },
    maxDate: { Date, required: false, default: null },
    clearable: { Boolean, required: false, default: true },
    datePlaceholder: { String, required: false, default: '' },
    css_class: { String, required: false, default: '' },
    presetDates: { Array, required: false, default: [] },
  } );
  const emit = defineEmits( [ 'change', 'clear-date' ] );
  const datePicker = ref( null );
  const { t } = useI18n();
  const componentKeyInit = ref ( 0 );
  // eslint-disable-next-line vue/no-setup-props-destructure
  const fieldDate = computed( () => {
    return [ moment( props.start_date ), moment( props.end_date ) ];
  } );
  const params = ref( {
    locale: store.getters.getLocale,
    format: 'dd/mm/yyyy - dd/mm/yyyy'
  } );

  const forceInitRerender = () => {
    componentKeyInit.value += 1;
  };

  const init = async () => {
    moment.locale( store.getters.getLocale );
    params.value.format = moment.localeData().longDateFormat( 'L' ).replaceAll( 'D', 'd' ).replaceAll( 'Y', 'y' );
    forceInitRerender();
  };

  const handleDate = ( date ) => {
    if ( date ) {
      let newRangeDate = [ moment( date[ 0 ] ).format( 'YYYY-MM-DD' ), moment( date[ 1 ] ).format( 'YYYY-MM-DD' ) ];
      emit( 'change', newRangeDate, props.rkey );
    } else {
      fieldDate.value = moment( props.field );
    }
  };

  const selectDate = () => {
    datePicker.value.selectDate();
  };

  const closeMenu = () => {
    datePicker.value.closeMenu();
  };

  const clearedDate = () => {
    emit( 'clear-date', props.rkey );
  };

  init();
</script>