<template>
  <GlobalIndicatorStd>
    <template v-slot:header> {{ t('RP List') }} </template>
    <template v-slot:graph>
      <div class="flex w-auto justify-center m-1 w-full h-full">
        <div class="grid place-items-center text-center w-full h-full">
          <div class="flex flex-row justify-center mb-4 gap-x-10">
            <div class="flex flex-row items-center justify-center">
              <!-- Field searcheds selector -->
              <select @change="select_changed($event)"
                      class="text-gray-800 bg-gray-150 border-[3px] border-gray-150 text-sm rounded-l-lg p-2">
                <option value="">{{ t("All fields") }}</option>
                <option v-for="item in headers" :key="item.searchValue ?? item.value" :value="item.value">
                    {{ item.text }}
                </option>
              </select>
              <!-- Search field -->
              <input type="search" :placeholder="t( 'Search bar' ) + '...'" v-model="searchedValue"
              class="bg-gray-150 text-sm w-96 rounded-r-lg p-2 border-2 border-gray-150"/>
            </div>
            <AddButton :tooltipContent="t( 'Create a new problem resolution' )" :rounded="true" @add="add" class="w-[3rem] h-[3rem]"></AddButton>
          </div>
          <!-- List of resolutions -->
          <EasyDataTable v-if="items" alternating buttons-pagination
            class="w-full h-full"
            :empty-message="t( 'No Available Data' )"
            :rows-of-page-separator-message="t( 'of' )"
            :rows-items="[25, 50, 75, 100]"
            :rows-per-page-message="t( 'Show' )"
            :rows-per-page="display.row_per_page"
            :headers="headers"
            :items="items"
            :search-value="searchedValue"
            :search-field="searchedField"
            sort-by="last_activity"
            sort-type="desc"
            @click-row="show_RP"
            table-class-name="customize-table">
            <template #item-last_activity="item">
              {{ item.last_activity_formatted ?? '' }}
            </template>
            <template #item-pilote="item">
              {{ item.piloteFullname ?? '' }}
            </template>
          </EasyDataTable>
          <div class="relative w-24 h-24 animate-spin rounded-full bg-gradient-to-r from-primary to-primary-hover place-self-center my-20" v-if="!items">
            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 bg-white rounded-full border-2 border-white"></div>
          </div>
        </div>
      </div>
    </template>
  </GlobalIndicatorStd>
</template>

<script setup>
  import GlobalIndicatorStd from '@/components/indicators/global_indicators/GlobalIndicatorStd.vue';
  import AddButton from '@/components/buttons/AddButton.vue';
  import API from '@/constants/api.constants';
  import router from '@/router';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import moment from 'moment';
  import AXIOS from '@/features/axios.js';
  import { ref, computed, onMounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { RP_FIELD } from '@/constants/rp.constants.js';

  const { t } = useI18n();

  const headers = computed( () => {
    return [
      { text: t( 'Number' ), value: 'number', sortable: true },
      { text: t( 'Title' ), value: 'title', sortable: true },
      { text: t( 'Pilote' ), value: 'piloteFullname', sortable: true },
      { text: t( 'Client name' ), value: 'client_name', sortable: true },
      { text: t( 'Client number' ), value: 'client_number', sortable: true },
      { text: t( 'State' ), value: 'state', sortable: true },
      { text: t( 'Last Activity' ), value: 'last_activity', searchValue: 'last_activity_formatted', sortable: true }
    ];
  } );
  const searchedValue = ref( '' );
  const searchedField = ref();
  const items = ref( null );
  const display = ref( {
    row_per_page: store.getters.getDisplay.dashboard.row_per_page
  } );

  const piloteFullname = ( pilote ) => {
    let res = '';
    if ( pilote != null ) {
      if ( pilote.firstname != null && pilote.firstname != '' ) {
        res += pilote.firstname + ' ';
      }
      if ( pilote.lastname != null && pilote.lastname != '' ) {
        res += pilote.lastname;
      }
    }
    return res.trim();
  };

  const init = () => {
    moment.locale( store.getters.getLocale );
    let url = API.get_api( API.API_NAMES.RP_FOR_USER, store.getters.get_connected_user.id );
    AXIOS.get( url, { params: {  }, headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        let data = [];
        if ( response.data.length > 0 ) {
          data = response.data.map( ( element ) => (
            {
              id: element[ RP_FIELD.ID ] ?? '',
              number: element[ RP_FIELD.NUMBER ] ?? '',
              title: element[ RP_FIELD.TITLE ] ?? '',
              client_name: element[ RP_FIELD.CLIENT_NAME ] ?? '',
              client_number: element[ RP_FIELD.CLIENT_NUMBER ] ?? '',
              state: element[ RP_FIELD.STATE ] ? t( element[ RP_FIELD.STATE ] ) : '',
              piloteFullname: piloteFullname( element[ RP_FIELD.PILOT ] ) ?? '',
              last_activity: element[ RP_FIELD.LAST_ACTIVITY ] ?? '',
              last_activity_formatted: element[ RP_FIELD.LAST_ACTIVITY ] ? moment( element[ RP_FIELD.LAST_ACTIVITY ] ).format( 'L LT' ) : ''
            } ) );
        }
        items.value = data;
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };

  onMounted( init );

  const show_RP = ( item ) => {
    router.push( '/rp/' + item.id );
  };

  const select_changed = ( event ) => {
    searchedField.value = event.target.value;
  };

  const add = () => {
    let url = API.get_api( API.API_NAMES.RP );
    let params = {
      title: t( 'New problem resolution' ),
      pilote: store.getters.get_connected_user.id,
      site: store.getters.get_connected_user.sites[ 0 ]
    };
    AXIOS.post( url, params, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '200' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          show_RP( response.data );
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };
</script>

<style lang="scss" scoped>
  .customize-table {
    --easy-table-header-font-size: 16px;
    --easy-table-header-height: 40px;
    --easy-table-body-row-height: 42px;
    --easy-table-body-row-font-size: 15px;
    --easy-table-body-item-padding: 10px 15px;
    --easy-table-body-even-row-background-color : #f6f9f6;
    --easy-table-footer-font-size: 15px;
    --easy-table-footer-padding: 0px 10px;
    --easy-table-footer-height: 45px;
    --easy-table-rows-per-page-selector-width: 100%;
    --easy-table-rows-per-page-selector-option-padding: 10px;
    --easy-table-rows-per-page-selector-z-index: 1;
  }
</style>