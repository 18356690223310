<template>
  <div :key="componentKey">
    <div class="flex flex-row gap-4 items-center">
      <span v-if="utils.isEfficientAction( item )"
        class="w-fit bg-green-500 text-white text-center text-xs font-semibold w-64 mr-2 px-2.5 py-0.5 h-5 rounded">
        {{ t( 'Efficient' ) }}
      </span>
      <span v-else-if="utils.isCompletedAction( item )"
        class="w-fit bg-blue-500 text-white text-center text-xs font-semibold w-64 mr-2 px-2.5 py-0.5 h-5 rounded">
        {{ t( 'Completed' ) }}
      </span>
      <div v-else-if="utils.isPlannedAction( item )"
        class="flex flex-row w-fit">
        <span class="w-fit bg-purple-600 text-white text-center text-xs font-semibold mr-2 px-2.5 py-0.5 h-5 rounded">
          {{ t( 'Planned Singular' ) }}
        </span>
        <div v-if="lateDays > 0"
          class="py-0.5 px-1 w-min rounded bg-red-600 text-white text-xs font-bold"
          droppable="true">
          {{ lateDays }}
        </div>
      </div>
      <div class="text-secondary-text font-bold">{{ t( 'Pilote' ) }}</div>
      <InputSelect :items="users" :selected="item.user.id" :readonly="isReadOnly"
        @change-value="changePilote"/>
      <div class="flex w-[16rem] min-w-[16rem] w-fit space-x-3 items-center">
        <FieldDate :title="t('Due date')"
          :field="item.delivery_date"
          :readonly="isReadOnly"
          @change="changeDeliveryDate"
          @clear-date="changeDeliveryDate('')"
          :minDate="minDeliveryDate"/>
      </div>
      <button v-if="!isReadOnly"
        class="hover:bg-tertiary-hover rounded-full" @click="remove">
        <font-awesome-icon icon="fa-solid fa-xmark" size="xl" class="px-2 py-1"/>
      </button>
    </div>
    <div v-if="( utils.isCompletedAction( item ) ||
      utils.isEfficientAction( item ) ) &&
      item.is_efficient != null"
      class="flex flex-row space-x-4 justify-start items-center">
      <div class="text-secondary-text font-bold">
        {{ t( 'Action efficient' ) }}
      </div>
      <input type="checkbox" @click="changeEfficiency( !item.is_efficient )"
        :checked="item.is_efficient"
        class="w-6 h-6 accent-green-600/95 rounded"
        :class="!(props.readonly || isBusy) ? 'cursor-pointer' : ''"
        :disabled="readonly || isBusy">
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import store from '@/store';
  import moment from 'moment';
  import AXIOS from '@/features/axios.js';
  import FieldDate from '@/components/object/FieldDate.vue';
  import InputSelect from '@/components/object/InputSelect.vue';
  import API from '@/constants/api.constants';

  const props = defineProps( {
    item: { Object, required: true },
    users: { Array, required: true },
    readonly: { Boolean, required: false, default: false }
  } );

  const emit = defineEmits( [ 'changeItem' ] );

  const { t } = useI18n();

  const minDeliveryDate = ref( moment().toDate() );

  const isBusy = ref( false );

  const componentKey = ref ( 0 );

  const forceRerender = () => {
    componentKey.value += 1;
  };

  const lateDays = computed( () => {
    return props.item.delivery_date != null &&
      props.item.delivery_date != ''
      ? moment( ).diff( props.item.delivery_date, 'days' )
      : 0;
  } );

  const isReadOnly = computed( () => {
    return utils.isCompletedAction( props.item ) ||
      props.readonly;
  } );

  const busy = () => {
    isBusy.value = !isBusy.value;
  };

  const changePilote = ( val ) => {
    let url = API.get_api( API.API_NAMES.ACTIONS_USERS, props.item.id );
    AXIOS.put( url, { id_users: val }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          emit( 'changeItem' );
        } else {
          forceRerender();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const changeDeliveryDate = ( val ) => {
    let url = API.get_api( API.API_NAMES.ACTIONS_USERS, props.item.id );
    AXIOS.put( url, { delivery_date: val }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          emit( 'changeItem' );
        } else {
          forceRerender();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const changeEfficiency = ( val ) => {
    busy();
    let url = API.get_api( API.API_NAMES.ACTIONS_USERS, props.item.id );
    AXIOS.put( url, { is_efficient: val }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          emit( 'changeItem' );
        } else {
          forceRerender();
        }
        busy();
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
        busy();
      } );
  };

  const remove = () => {
    let url = API.get_api( API.API_NAMES.ACTIONS_USERS, props.item.id );
    AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        if ( response.status == '201' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          emit( 'changeItem' );
        } else {
          forceRerender();
        }
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };
</script>
