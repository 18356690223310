<template>
  <button class="inline-flex justify-center items-center w-full h-8
               bg-primary hover:bg-primary-hover disabled:bg-primary-hover  disabled:cursor-not-allowed
               text-primary-text
               font-bold px-4 rounded"
          :class="isDisabled ? ' ' : ''"
          type="button" :disabled="loading || isDisabled" @click="startLoading()">
    <span v-if="!loading" class="text-base">{{ label }}</span>
    <ClipLoader v-if="loading" color="#ffffff" size="1.3rem" class="inline-flex"/>
  </button>
</template>

<script setup>
  import { ref } from 'vue';
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';

  defineProps( {
    label: { String, required: true },
    isDisabled: { Boolean, required: false, default: false }
  } );

  const loading = ref ( false );

  const startLoading = () => {
    loading.value = true;
  };

  const stopLoading = () => {
    loading.value = false;
  };

  defineExpose( { startLoading, stopLoading } );
</script>
