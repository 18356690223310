import AXIOS from '@/features/axios.js';
import utils from '@/features/utils.js';
import store from '@/store';
import API from '@/constants/api.constants.js';

const RESET_KEYS = {
  ISHIKAWA_7M: 'ishikawa_7m',
  CAPITALISATION_TEMPLATE: 'capitalisation_template',
  IS_IS_NOT_TEMPLATE: 'is_is_not_template',
  PRIORITY_MATRIX: 'priority_matrix_template'
};

const getSettings = async () => {
  return new Promise( ( resolve ) => {
    AXIOS.get( API.API_NAMES.SETTING + store.getters.getLocale, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
        resolve( response.data );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
        resolve( [] );
      } );
  } );
};

const getOneSetting = async ( key ) => {
  return new Promise( ( resolve ) => {
    AXIOS.get( API.API_NAMES.SETTING + key + '/' + store.getters.getLocale, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
        resolve( response.data[ key ] );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
        resolve( [] );
      } );
  } );
};

const setOneSetting = async ( key, value ) => {
  let data = {};
  data[ key ] = value;

  return new Promise( ( resolve ) => {
    AXIOS.put( API.API_NAMES.SETTING, data, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
        resolve();
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
        resolve();
      } );
  } );
};

const setCompanyLogo = async ( file ) => {
  let data = new FormData();
  data.append( 'file', file );

  return new Promise( ( resolve ) => {
    AXIOS.put( API.API_NAMES.SETTING + 'logo', data, { headers: { 'auth-token': store.getters.getToken }, 'Content-Type': 'multipart/form-data' } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
        resolve();
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
        resolve();
      } );
  } );
};

const deleteLogo = async () => {
  return new Promise( ( resolve ) => {
    AXIOS.delete( API.API_NAMES.SETTING + 'logo', { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
        resolve();
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
        resolve();
      } );
  } );
};

const resetSetting = async ( key ) => {
  let body = {};

  return new Promise( ( resolve ) => {
    AXIOS.put( API.get_api( API.API_NAMES.RESET_SETTING, key ), body, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
        resolve();
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
        resolve();
      } );
  } );
};

export default {
  getSettings, getOneSetting, setOneSetting, setCompanyLogo, deleteLogo, resetSetting, RESET_KEYS
};