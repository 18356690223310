<template>
  <div class="flex flex-col text-xl space-y-5 min-w-[30rem]">
    <!-- Modal header -->
    <h1 class="text-xl font-bold text-center px-10">{{ t( 'Add a criterion' ) }}</h1>

    <!-- Modal Body -->
    <Field :title="t( 'Section label' )"
      :placeholder="t( 'Label' )"
      :field="template.label"
      fkey="label"
      @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.TOOL_PRIORITY_MATRIX_LABEL"/>
    <FieldArea :title="t( 'Criterion description' )"
      :placeholder="t( 'Criterion description' )"
      :field="template.label_tooltip"
      fkey="label_tooltip"
      class="text-lg"
      @change="changeField"
      :maxlength="MAX_INPUT_LENGTH.TOOL_PRIORITY_MATRIX_LABEL_TOOLTIP"/>

    <!-- Modal footer-->
    <div class="flex space-x-4 justify-end">
      <div>
        <LoadingButton :label="t( 'Add' )" @click="add" :disabled="!canAdd"/>
      </div>
      <div>
        <LoadingButton :label="t( 'Cancel' )" @click="close"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import Field from '@/components/object/FieldBasic.vue';
  import FieldArea from '@/components/object/FieldArea.vue';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const emit = defineEmits( [ 'add', 'close' ] );

  const template = ref( { label: null, label_tooltip: null } );

  const { t } = useI18n();

  const canAdd = computed( () => {
    return template.value.label != null && template.value.label.trim() !== '';
  } );

  const changeField = ( ...val ) => {
    if ( val && val[ 0 ] && val[ 0 ] != '' && val[ 1 ] != null ) {
      template.value[ val[ 0 ] ] = val[ 1 ];
    }
  };

  const add = () => {
    if ( template.value != null && canAdd ) {
      emit( 'add', template.value );
    } else {
      close();
    }
  };

  const close = () => {
    emit( 'close' );
  };
</script>