import { useNotificationStore } from '@dafcoe/vue-notification';
import vueNotificationOptions from '@/features/vue-notification-options.js';
import router from '@/router';
import SORT from '@/constants/sort.constants';
import FILTER from '@/constants/filter.constants';

const { setNotification } = useNotificationStore();

export default {
  is_true( val ) {
    if ( val === 1 || val === '1' || val === 'true' || val === true ) {
      return true;
    } else {
      return false;
    }
  },

  get_api_base_url() {
    let index = process.env.VUE_APP_API_BASE_URL.indexOf( 'PLACEHOLDER' );
    if (  index < 0 ) {
      return process.env.VUE_APP_API_BASE_URL;
    } else {
      return 'http://localhost:8081';
    }
  },

  json_path( keys, obj ) {
    return keys.reduce( ( obj, key ) => {
      return typeof obj !== 'undefined'
        ? obj[ key ]
        : void 0;
    }, obj );
  },

  get_idx_theme_color( themes, number ) {
    if ( Array.isArray( themes ) ) {
      for ( let i = 0; i < themes.length; i++ ) {
        if ( themes[ i ] != null && themes[ i ] == number ) {
          return i;
        }
      }
    }
    return 0;
  },

  analyse_axios_request( response ) {
    if ( response.data.signin == false ) {
      setNotification( { ...vueNotificationOptions, ...{ 'message': response.data.msg, 'type': 'warning' } } );
      setTimeout( () => {
        router.push( '/login' );
      }, 2000 );
    }
  },

  showSucess( msg ) {
    setNotification( { ...vueNotificationOptions, ...{ 'message': msg, 'type': 'sucess' } } );
  },

  showWarning( msg ) {
    console.warn( msg );
    setNotification( { ...vueNotificationOptions, ...{ 'message': msg, 'type': 'warning' } } );
  },

  showAlert( msg ) {
    console.error( msg );
    setNotification( { ...vueNotificationOptions, ...{ 'message': msg, 'type': 'alert' } } );
  },

  async showCatch( e ) {
    if ( e != null && e.response != null && e.response.data != null ) {
      let data = e.response.data;
      let msg = '';

      if ( e.response.data instanceof Blob ) {
        data = JSON.parse( await e.response.data.text() );
      }

      msg += data.err ? data.err + ' - ' : '';
      msg += data.msg ? data.msg : '';
      this.showAlert( msg );
    }
  },

  async showAxiosError( response ) {
    if ( response != null
      && response.status != null
      && response.status !== 200 && response.status !== 201
      && response.data != null ) {

      let data = response.data;
      let msg = '';

      if ( response.data instanceof Blob ) {
        data = JSON.parse( await response.data.text() );
      }

      msg += data.err ? data.err + ' - ' : '';
      msg += data.msg ? data.msg : '';
      this.showWarning( msg );
    }
  },

  sortListByRegisterDateAscending( list ) {
    return list != null && Array.isArray( list )
      ? list.sort( ( item1, item2 ) => {
        if ( item1.register_date != null && item2.register_date != null ) {
          return new Date( item1.register_date ) - new Date( item2.register_date );
        }
        else { return 0; }
      } )
      : [];
  },

  sortListByLastActivityAscending( list ) {
    return list != null && Array.isArray( list )
      ? list.sort( ( item1, item2 ) => {
        if ( item1.last_activity != null && item2.last_activity != null ) {
          return new Date( item1.last_activity ) - new Date( item2.last_activity );
        }
        else { return 0; }
      } )
      : [];
  },

  sortListByPositionAscending( list ) {
    return list != null && Array.isArray( list )
    ? list.sort( ( item1, item2 ) => {
      if ( item1.position != null && item2.position != null ) {
        return item1.position - item2.position;
      }
      else { return 0; }
    } )
    : [];
  },

  sortActions( sortParam, actionsList ) {
    if ( actionsList != null && Array.isArray( actionsList ) ) {
      if ( sortParam != null ) {
        switch ( sortParam ) {
        case ( SORT.REGISTER_DATE_DESC ):
          return this.sortListByRegisterDateAscending( actionsList ).toReversed();
        case ( SORT.REGISTER_DATE_ASC ):
          return this.sortListByRegisterDateAscending( actionsList );
        case ( SORT.LAST_ACTIVITY_DESC ):
          return this.sortListByLastActivityAscending( actionsList ).toReversed();
        case ( SORT.LAST_ACTIVITY_ASC ):
          return this.sortListByLastActivityAscending( actionsList );
        case ( SORT.TITLE_DESC ):
          return actionsList.sort( ( action1, action2 ) => {
            if ( action1.title != null && action2.title != null ) {
              return action1.title.toLowerCase() < action2.title.toLowerCase() ? 1 : -1;
            }
            else { return 0; }
          } );
        case ( SORT.TITLE_ASC ):
          return actionsList.sort( ( action1, action2 ) => {
            if ( action1.title != null && action2.title != null ) {
              return action1.title.toLowerCase() > action2.title.toLowerCase() ? 1 : -1;
            }
            else { return 0; }
          } );
        default:
          this.sortListByRegisterDateAscending( actionsList ).toReversed();
        }
      }
      else { return actionsList; }
    }
    return [];
  },

  sortCauses( sortParam, causesList ) {
    if ( causesList != null && Array.isArray( causesList ) ) {
      if ( sortParam != null ) {
        switch ( sortParam ) {
        case ( SORT.REGISTER_DATE_DESC ):
          return this.sortListByRegisterDateAscending( causesList ).toReversed();
        case ( SORT.REGISTER_DATE_ASC ):
          return this.sortListByRegisterDateAscending( causesList );
        case ( SORT.LAST_ACTIVITY_DESC ):
          return this.sortListByLastActivityAscending( causesList ).toReversed();
        case ( SORT.LAST_ACTIVITY_ASC ):
          return this.sortListByLastActivityAscending( causesList );
        case ( SORT.RANKING_ASC ):
          return this.sortListByPositionAscending( causesList );
        case ( SORT.RANKING_DESC ):
          return this.sortListByPositionAscending( causesList ).toReversed();
        default:
          this.sortListByRegisterDateAscending( causesList ).toReversed();
        }
      }
      else { return causesList; }
    }
    return [];
  },

  isIdentifiedAction( action ) {
    let actionUser = action;

    if ( action != null &&
      action.action_users != null &&
      Array.isArray( action.action_users ) &&
      action.action_users.length == 0 ) {

      return true;
    } else if ( action != null &&
      action.action_users != null &&
      Array.isArray( action.action_users ) &&
      action.action_users.length > 0 &&
      action.action_users[ 0 ] != null ) {

      actionUser = action.action_users[ 0 ];
    }
    return (
      actionUser.completed_date == null ||
      actionUser.completed_date == ''
      ) &&
      ( actionUser.delivery_date == null ||
        actionUser.delivery_date == ''
      );
  },

  isPlannedAction( action ) {
    let actionUser = action;

    if ( action != null &&
      action.action_users != null &&
      Array.isArray( action.action_users ) &&
      action.action_users.length == 0 ) {

      return false;
    } else if ( action != null &&
      action.action_users != null &&
      Array.isArray( action.action_users ) &&
      action.action_users.length > 0 &&
      action.action_users[ 0 ] != null ) {

      actionUser = action.action_users[ 0 ];
    }
    return (
      actionUser.delivery_date != null &&
      actionUser.delivery_date != '' &&
      (
        actionUser.completed_date == null ||
        actionUser.completed_date == ''
      )
    );
  },

  isCompletedAction( action ) {
    let actionUser = action;

    if ( action != null &&
      action.action_users != null &&
      Array.isArray( action.action_users ) &&
      action.action_users.length == 0 ) {

      return false;
    } else if ( action != null &&
      action.action_users != null &&
      Array.isArray( action.action_users ) &&
      action.action_users.length > 0 &&
      action.action_users[ 0 ] != null ) {

      actionUser = action.action_users[ 0 ];
    }
    return (
        actionUser.delivery_date != null &&
        actionUser.delivery_date != '' &&
        actionUser.completed_date != null &&
        actionUser.completed_date != '' &&
        actionUser.is_efficient != null &&
        actionUser.is_efficient == false
    );
  },

  isEfficientAction( action ) {
    let actionUser = action;

    if ( action != null &&
      action.action_users != null &&
      Array.isArray( action.action_users ) &&
      action.action_users.length == 0 ) {

      return false;
    } else if ( action != null &&
      action.action_users != null &&
      Array.isArray( action.action_users ) &&
      action.action_users.length > 0 &&
      action.action_users[ 0 ] != null ) {

      actionUser = action.action_users[ 0 ];
    }
    return (
        actionUser.delivery_date != null &&
        actionUser.delivery_date != '' &&
        actionUser.completed_date != null &&
        actionUser.completed_date != '' &&
        actionUser.is_efficient != null &&
        actionUser.is_efficient == true
    );
  },

  filterActions( filterParam, actionsList ) {
    if ( filterParam != null && actionsList != null && Array.isArray( actionsList ) ) {
      switch ( filterParam ) {
      case ( FILTER.ALL ):
        return actionsList;
      case ( FILTER.WITH_PILOT ):
        return actionsList.filter( ( action ) => {
          return ( action.action_users != null
            && Array.isArray( action.action_users )
            && action.action_users.length > 0 );
        } );
      case ( FILTER.WITHOUT_PILOT ):
        return actionsList.filter( ( action ) => {
          return action.action_users == null ||
            ( action.action_users != null
              && Array.isArray( action.action_users )
              && action.action_users.length == 0 );
        } );
      case ( FILTER.IDENTIFIED ):
        return actionsList.filter( ( action ) => this.isIdentifiedAction( action ) );
      case ( FILTER.PLANNED ):
        return actionsList.filter( ( action ) => this.isPlannedAction( action ) );
      case ( FILTER.COMPLETED ):
        return actionsList.filter( ( action ) => this.isCompletedAction( action ) );
      case ( FILTER.EFFICIENT ):
        return actionsList.filter( ( action ) => this.isEfficientAction( action ) );
      default:
        return actionsList;
      }
    }
    return [];
  },

  causeImgPath( imgPath ) {
    return imgPath != null && imgPath != ''
      ? this.get_api_base_url() + '/public' + imgPath
      : '';
  }
};