<template>
  <IconEditButton :css="'hover:bg-secondary-hover'"
    :disableLoading="disableLoading"
    tooltipContent="Cancel"
    ref="actionBtn"
    icon="fa-solid fa-xmark" />
</template>

<script setup>
  import { ref } from 'vue';
  import IconEditButton from '@/components/buttons/IconEditButton';

  defineProps( {
    disableLoading: { Boolean, default: false }
  } );

  const actionBtn = ref( null );

  const startLoading = () => { actionBtn.value.startLoading(); };

  const stopLoading = () => { actionBtn.value.stopLoading(); };

  defineExpose( { startLoading, stopLoading } );
</script>
