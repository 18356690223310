<template>
  <div class="relative popup max-w-[95vw] min-w-[30vw] max-h-[95vh] w-full">

    <MatrixColumnDetailsModal v-if="selectedColumn != null"
      class="absolute popup animate-blinkingBg h-fit max-w-[60%] border-2 bottom-3 z-50"
      :column="selectedColumn"
      :isDeletable="canDeleteColumn"
      :readonly="readonly"
      @close="( selectedColumnId = null )"
      @changeField="changeColumnField"
      @remove="deleteProperty"
      :key="componentKey + selectedColumnId"/>

    <AddMatrixColumnModal v-if="showAddColumnModal && !readonly"
      class="absolute popup animate-blinkingBg h-fit max-w-[60%] border-2 bottom-3 z-50"
      :isAddable="canAddColumn"
      @close="( showAddColumnModal = false )"
      @add="add"
      :key="componentKey"/>

    <!-- Close modal button -->
    <div class="relative z-10">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="relative flex justify-center">
      <div class="absolute flex left-0 -top-2 flex items-center space-x-6">
        <AddButton v-if="canAddColumn && !readonly && matrixView"
          :rounded="true"
          @click="clickAddColumn"
          class="w-[3rem] h-[3rem]"/>
        <div class="flex items-center space-x-2">
          <font-awesome-icon icon="fa-solid fa-chart-column" class="clickable" size="xl" @click="toggleView"/>
          <ToggleBasic id="toggleView" :value="matrixView"
            @toggle="toggleView"/>
          <font-awesome-icon icon="fa-solid fa-table-cells" class="clickable" size="xl" @click="toggleView"/>
        </div>
      </div>
      <div class="has-tooltip flex flex-row w-fit text-2xl font-bold text-center">
        <p>{{ t( 'Tool-priority-matrix' ) }}</p>
        <font-awesome-icon class="pl-1" icon="fa-solid fa-circle-info" style="color: #2563eb;" size="xs"/>
        <span class='tooltip mt-10 mx-20'>{{ t( 'Tool-priority-matrix tooltip' )}}</span>
      </div>
    </div>

    <div v-if="matrixView" class="flex w-full max-h-[70vh] min-h-[60vh] overflow-auto">
      <div class="flex-col">
        <!-- Matrix header -->
        <div class="flex pl-[10rem]">
          <div v-for="column in matrixData" :key="column.id"
            class="w-full border-l-4">
            <div class="flex flex-col font-bold text-xl h-full justify-end text-center w-[9.7rem] py-2 transition duration-200 clickable hover:bg-tertiary-hover"
              @click="columnDetails( column )">
              <div class="has-tooltip relative">
                <p>{{ column.label }}</p>
                <font-awesome-icon v-if="column.label_tooltip" class="pl-1 absolute top-0 right-1" icon="fa-solid fa-circle-info" style="color: #2563eb;" size="xs"/>
                <span v-if="column.label_tooltip" class='tooltip mt-3'>{{ column.label_tooltip }}</span>
              </div>
              <div class="flex justify-center">
                <div v-for="box in DEFAULT_WEIGHT_VALUES" :key="box.weight"
                  @hover.stop
                  @click.stop
                  :class="column.weight == box.weight ? ' bg-blue-400' : 'transition duration-200 hover:bg-fourth-hover'"
                  class="flex text-xl clickable rounded-sm w-8 aspect-square font-bold justify-center items-center"
                  @click="changeColumnWeight( column, box.weight )">
                  {{ box.weight }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-none justify-center text-center items-end w-40 pb-2 px-2 border-l-4">
            <p class="font-bold text-xl">
              {{ t( 'Causes weight' ) }}
            </p>
          </div>
        </div>

        <div class="flex-col w-full">
          <div v-for="row in formatedMatrix.value" :key="row"
            class="flex">
            <div v-for="cell in row" :key="cell">

              <div v-if="cell.cellType === CELL_TYPES[ 0 ]"
                class="flex font-bold justify-center items-center w-40 py-2 aspect-square border-t-4"
                @click="clickCause( cell.id )">
                <img v-if="cell.path != null && cell.path !== ''"
                  class="aspect-square h-full"
                  :id_cause="cell.id"
                  :src="utils.causeImgPath( cell.path )"/>
                <div v-else
                  class="bg-yellow-300 border border-black aspect-square rounded-sm h-full px-1 pt-0.5 text-lg/[18px] break-words overflow-hidden">
                  {{ cell.description }}
                </div>
              </div>

              <div v-else-if="cell.cellType === CELL_TYPES[ 1 ]"
                class="grid grid-cols-2 content-center pl-4 w-40 aspect-square border-l-4 border-t-4">
                <div v-for="box in ( cell.is_ascending === 1 ? DEFAULT_WEIGHT_VALUES : DEFAULT_WEIGHT_VALUES_REVERSED )" :key="box.weight"
                  :class="cell.weight == box.weight ? ' bg-blue-400' : 'transition duration-200 hover:bg-fourth-hover'"
                  class="flex text-xl rounded-sm w-14 aspect-square font-bold justify-center items-center"
                  @click="changeCauseWeight( cell.columnId, cell, box.weight )">
                  {{ box.weight }}
                </div>
              </div>

              <div v-else-if="cell.cellType === CELL_TYPES[ 2 ]"
                class="flex justify-center items-center w-40 py-2 aspect-square border-t-4 border-l-4">
                <span class="font-bold text-3xl">
                  {{ cell.weight }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="w-[100rem] h-[45rem]" >
      <HierarchyParetoChart :id_rp="id_rp" @clickCause="clickCause"/>
    </div>

    <!-- Modal footer-->
    <div v-if="!readonly" class="flex justify-end space-x-2">
      <div>
        <LoadingButton :label="t( 'Criteria templates' )"
          @click="openCriteriaTemplateList"
          ref="criteriaBtn"/>
      </div>
      <div>
        <LoadingButton :label="t( 'Hierarchize causes' )"
          @click="hierarchize"
          ref="hierarchizeBtn"/>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, reactive, watchEffect } from 'vue';
  import { useI18n } from 'vue-i18n';
  import AddButton from '@/components/buttons/AddButton.vue';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import AXIOS from '@/features/axios.js';
  import utils from '@/features/utils.js';
  import API from '@/constants/api.constants.js';
  import store from '@/store';
  import MatrixColumnDetailsModal from '@/components/modals/tools_modals/_MatrixColumnDetailsModal.vue';
  import AddMatrixColumnModal from '@/components/modals/tools_modals/_AddMatrixColumnModal.vue';
  import HierarchyParetoChart from '@/components/indicators/rp_indicators/RPHierarchyParetoChart.vue';
  import ToggleBasic from '@/components/object/ToggleBasic.vue';
  import MatrixCriteriaList from '@/components/modals/tools_modals/MatrixCriteriaList.vue';
  import { useModal, useModalSlot } from 'vue-final-modal';
  import Modal from '@/components/modals/ModalBasic.vue';

  const props = defineProps( {
    readonly: { Boolean, required: false, default: false },
    causes: { Array, required: true, default: [] },
    matrixData: { Array, required: true, default: [] },
    id_rp: { Array, required: true, default: '' }
  } );

  const emit = defineEmits( [
    'clickClose',
    'changeMatrix',
    'changeMatrixCell',
    'clickCause',
    'changeMatrixColumn',
    'hierarchize'
  ] );

  const DEFAULT_WEIGHT_VALUES = [
    { weight: '1' },
    { weight: '3' },
    { weight: '7' },
    { weight: '10' },
  ];
  const DEFAULT_WEIGHT_VALUES_REVERSED = DEFAULT_WEIGHT_VALUES.slice().reverse();

  const CELL_TYPES = [ 'cause', 'cell', 'total' ];

  const MAX_NUMBER_OF_COLUMNS = 8;

  const MIN_NUMBER_OF_COLUMNS = 3;

  const matrixView = ref( true );

  const hierarchizeBtn = ref( null );

  const criteriaBtn = ref( null );

  const componentKey = ref( 0 );

  const canDeleteColumn = computed( () => {
    return props.matrixData.length > MIN_NUMBER_OF_COLUMNS;
  } );

  const canAddColumn = computed( () => {
    return props.matrixData.length < MAX_NUMBER_OF_COLUMNS;
  } );

  const { t } = useI18n();

  const formatedMatrix = reactive( { value: null } );

  const selectedColumnId = ref( null );

  const selectedColumn = computed( () => {
    let res = null;
    if ( selectedColumnId.value != null ) {
      res = props.matrixData.find( ( column ) => column.id == selectedColumnId.value ) ?? null;
    }
    return res;
  } );

  const showAddColumnModal = ref( false );

  const reRenderModal = () => {
    componentKey.value += 1;
  };

  watchEffect( () => {
    let formattedData = [];
    let sortedColumnsList = utils.sortListByRegisterDateAscending( props.matrixData );

    utils.sortListByRegisterDateAscending( props.causes ).forEach( ( cause ) => {
      let formatedRow = [];
      let totalCauseWeight = 0;

      cause.cellType = CELL_TYPES[ 0 ];
      formatedRow.push( cause );

      sortedColumnsList.forEach( ( column ) => {
        column.priority_matrix_causes.every( ( columnCause ) => {
          if ( cause.id == columnCause.id ) {
            columnCause.cellType = CELL_TYPES[ 1 ];
            columnCause.columnId = column.id;
            columnCause.is_ascending = column.is_ascending;

            formatedRow.push( columnCause );

            if ( columnCause.weight != null && column.weight != null ) {
              totalCauseWeight += parseInt( columnCause.weight ) * parseInt( column.weight );
            }

            return false;
          }
          return true;
        } );
      } );

      formatedRow.push( { cellType: CELL_TYPES[ 2 ], weight: totalCauseWeight } );

      formattedData.push( formatedRow );
    } );

    formatedMatrix.value = formattedData;
  } );

  const clickClose = () => {
    emit( 'clickClose' );
  };

  const clickCause = ( causeId ) => {
    let readonly = true;
    emit( 'clickCause', causeId, readonly );
  };

  const add = ( body ) => {
    if ( body != null && props.id_rp != null ) {
      body.id_rp = props.id_rp;

      let url = API.get_api( API.API_NAMES.TOOL_PRIORITY_MATRIX );

      AXIOS.post( url, body, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          utils.showAxiosError( response );
          emit( 'changeMatrix' );
          showAddColumnModal.value = false;
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
          showAddColumnModal.value = false;
        } );
    }
  };

  const changeCauseWeight = ( property_id, cause, weight ) => {
    if ( property_id != null && cause.id != null && weight != null && cause.weight != weight ) {
      let url = API.get_api( API.API_NAMES.TOOL_PRIORITY_MATRIX, property_id, 'causes' );
      let body =  {
        id_cause: cause.id,
        weight: weight
      };
      AXIOS.put( url, body, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          utils.showAxiosError( response );
          emit( 'changeMatrixCell', property_id, cause.id );
          // emit( 'changeMatrix' );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeColumnWeight = ( column, newWeight ) => {
    if ( column.id != null && newWeight != null && column.weight != newWeight ) {
      let url = API.get_api( API.API_NAMES.TOOL_PRIORITY_MATRIX, column.id );
      let body =  {
        weight: newWeight
      };
      AXIOS.put( url, body, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          utils.showAxiosError( response );
          emit( 'changeMatrixColumn', column.id );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const toggleView = () => {
    selectedColumnId.value = null;
    showAddColumnModal.value = false;
    matrixView.value = !matrixView.value;
  };

  const deleteProperty = () => {
    if ( selectedColumn.value.id != null && selectedColumn.value.id != '' ) {
      let url = API.get_api( API.API_NAMES.TOOL_PRIORITY_MATRIX, selectedColumn.value.id );
      AXIOS.delete( url, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          utils.showAxiosError( response );
          emit( 'changeMatrix' );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    }
  };

  const changeColumnField = ( fieldName, value ) => {
    if ( selectedColumn.value.id != null && fieldName != null && value != null ) {
      let url = API.get_api( API.API_NAMES.TOOL_PRIORITY_MATRIX, selectedColumn.value.id );
      let body = {};
      body[ fieldName ] = value;

      AXIOS.put( url, body, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          utils.showAxiosError( response );
          emit( 'changeMatrixColumn', selectedColumn.value.id );
          if ( response.status != '201' ) {
            reRenderModal();
          }
        } )
        .catch( ( err ) => {
          reRenderModal();
          utils.showCatch( err );
        } );
    }
  };

  const columnDetails = ( column ) => {
    if ( column.id != null ) {
      showAddColumnModal.value = false;
      selectedColumnId.value = column.id;
    }
  };

  const clickAddColumn = () => {
    selectedColumnId.value = null;
    showAddColumnModal.value = true;
  };

  const hierarchize = () => {
    if ( props.id_rp != null ) {
      let url = API.get_api( API.API_NAMES.RP_CAUSES, props.id_rp, 'sort' );
      AXIOS.put( url, {}, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          utils.showAxiosError( response );
          emit( 'hierarchize' );
          hierarchizeBtn.value.stopLoading();
          emit( 'clickClose' );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
          hierarchizeBtn.value.stopLoading();
        } );
    }
  };

  const openCriteriaTemplateList = () => {
    let criteriaListModal = useModal( {
      component: Modal,
      slots: {
        content: useModalSlot( {
          component: MatrixCriteriaList,
          attrs: {
            readonly: props.readonly,
            id_rp: props.id_rp,
            onClose( needUpdate ) {
              if ( needUpdate && needUpdate === true ) {
                emit( 'changeMatrix' );
              }
              criteriaListModal.close();
            }
          }
        } )
      },
      attrs: {
        onClosed() {
          criteriaBtn.value.stopLoading();
        }
      }
    } );
    criteriaListModal.open();
  };
</script>