<template>
  <VChart ref="echart"
    :option="option"
    :resize="resizeOptions"
    :autoresize="true"
    @click="selectNode"/>
</template>

<script setup>
  import { ref, watchEffect } from 'vue';

  // Apache E-Charts mandatory modules
  import { use } from 'echarts/core';

  // Apache E-Charts manually imported modules
  import { TooltipComponent } from 'echarts/components';
  import { TreeChart } from 'echarts/charts';
  import { SVGRenderer } from 'echarts/renderers';

  const props = defineProps( {
    data: { Object, required: true }
  } );

  const emit = defineEmits( [ 'selectNode' ] );

  use( [ TooltipComponent, TreeChart, SVGRenderer ] );

  const option = ref( null );

  const resizeOptions = ref( {
    width: 'auto',
    height: 'auto'
  } );

  const selectNode = ( event ) => {
    emit( 'selectNode', event.data );
  };

  watchEffect( () => ( option.value = {
    series: [
      {
        type: 'tree',
        name: '5Why tree',
        zlevel: 10,
        z: 10,
        data: [ props.data ],
        symbolSize: 35,
        symbol: 'circle',
        roam: true,
        zoom: 0.6,
        left: '-45%',
        right: '-5%',
        edgeShape: 'polyline',
        edgeForkPosition: '20%',
        initialTreeDepth: 5,
        itemStyle: {
          borderWidth: 3,
          borderColor: '#45D52E',
          color: '#fff'
        },
        selectedMode: true,
        select: {
          disabled: false,
          itemStyle: {
            borderColor: '#45D52E',
            color: '#45D52E'
          }
        },
        lineStyle: {
          width: 3
        },
        label: {
          backgroundColor: '#fff',
          position: 'left',
          verticalAlign: 'middle',
          align: 'right',
          fontSize: '16',
          formatter: '{c}'
        },
        leaves: {
          label: {
            position: 'right',
            verticalAlign: 'middle',
            align: 'left'
          }
        },
        emphasis: {
          focus: 'relative'
        },
        expandAndCollapse: false,
        animationDuration: 0,
        animationDurationUpdate: 500
      }
    ]
  } ) );
</script>