<template>
  <GlobalIndicatorStd :start_date="start_date" :end_date="end_date" :change_date="eventDateChange">
    <template v-slot:header> {{ t( 'Pareto Action Title Chart' ) }} </template>
    <template v-slot:graph>
      <div class="flex h-full w-full place-content-center">
        <VChart class="flex-none" :option="option" @click="eventClick" :autoresize="false" v-if="option"/>
        <div class="relative w-24 h-24 animate-spin rounded-full bg-gradient-to-r from-primary to-primary-hover place-self-center" v-if="!option">
          <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 bg-white rounded-full border-2 border-white"></div>
        </div>
      </div>
    </template>
  </GlobalIndicatorStd>
</template>

<script setup>
  import GlobalIndicatorStd from '@/components/indicators/global_indicators/GlobalIndicatorStd.vue';
  import PopupDisplayArray from '@/components/modals/PopupDisplayArray.vue';
  import { useI18n } from 'vue-i18n';
  import { use } from 'echarts/core';
  import utils from '@/features/utils.js';
  import { ref, watch } from 'vue';
  import store from '@/store';
  import moment from 'moment';
  import AXIOS from '@/features/axios.js';
  import API from '@/constants/api.constants';
  import {
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent
  } from 'echarts/components';
  import { BarChart, LineChart } from 'echarts/charts';
  import { SVGRenderer } from 'echarts/renderers';
  import { UniversalTransition } from 'echarts/features';
  import { useModal } from 'vue-final-modal';

  use( [
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    BarChart,
    SVGRenderer,
    UniversalTransition,
    LineChart
  ] );

  const DEFAULT_DATES = [ moment().startOf( 'year' ).format( 'YYYY-MM-DD' ), moment().startOf( 'day' ).format( 'YYYY-MM-DD' ) ];
  const emit = defineEmits( [ 'isBusy' ] );
  const { t } = useI18n();

  const props = defineProps( {
    readOnly: { Boolean, required: false, default: false }
  } );

  let option = ref( null );
  let actions_array = ref( [] );
  let start_date = ref( DEFAULT_DATES[ 0 ] );
  let end_date = ref( DEFAULT_DATES[ 1 ] );

  watch( props, async () => {
    if ( option.value ) {
      option.value.series[ 0 ].silent = props.readOnly;
    }
  } );

  const getDataIndicator = () => {
    // Get Data for Pareto Action Chart
    let url = API.get_api( API.API_NAMES.GLOBAL_INDICATORS_ACTIONS_PARETO, start_date.value, end_date.value );
    AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        var actions = [];
        var xAxisData = [];
        var pourcents = [];
        let max_yAxis = 0;
        let exist = false;

        response.data.forEach( ( element ) => {
          if ( max_yAxis < element.number_actions ) {
            max_yAxis = element.number_actions;
          }
          if (  element.number_actions === 0 && element.pourcent === 100 ) {
            if ( !exist ) {
              xAxisData.push( t( 'others' ) );
              actions.push( element.number_actions );
              pourcents.push( element.pourcent );
              exist = true;
            }
          } else {
            xAxisData.push( element.service );
            actions.push( element.number_actions );
            actions_array.value.push( element.actions );
            pourcents.push( element.pourcent );
          }
        } );

        option.value = {
          color: [ '#37A2DA', '#45D52E' ],
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            top: '5%',
            bottom: '0%',
            left: '3%',
            right: '1%',
            containLabel: true
          },
          xAxis: {
            data: xAxisData,
            axisLabel: {
              interval: 0,
              rotate: 45,
              fontSize: 10,
              width: 80,
              ellipsis: '...',
              overflow: 'truncate'
            }
          },
          yAxis: [
            {
              minInterval: 1,
              type: 'value',
              alignTicks: false,
              max: max_yAxis,
              axisLabel: {
                interval: 0,
                fontSize: 10
              },
            },
            {
              type: 'value',
              splitLine: {
                show: false
              },
              name: t( 'Percentage' ) + ' (%)',
              nameTextStyle: {
                fontSize: 10
              },
              gridIndex: 0,
              min: 0,
              max: 100,
              interval: 20,
              axisLabel: {
                formatter: '{value} %',
                fontSize: 10
              }
            }
          ],
          series: [
            {
              name: t( 'Planned actions' ),
              type: 'bar',
              yAxisIndex: 0,
              data: actions,
            },
            {
              name: t( 'Percentage' ),
              type: 'line',
              yAxisIndex: 1,
              data: pourcents,
              tooltip: {
                valueFormatter: function( value ) {
                  return value.toFixed( 1 ) + ' %';
                }
              },
              symbolSize: 9,
              lineStyle: {
                width: 3
              },
              emphasis: {
                scale: 1.4,
              },
              silent: true
            }
          ]
        };

        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        utils.showCatch( err );
      } );
  };
  getDataIndicator();

  function eventDateChange( params ) {
    option.value = null;
    actions_array.value = [];
    start_date.value = moment( params[ 0 ] ).format( 'YYYY-MM-DD' );
    end_date.value = moment( params[ 1 ] ).format( 'YYYY-MM-DD' );
    getDataIndicator();
  }

  function eventClick( params ) {
    if ( params ) {
      emit( 'isBusy' );
      let list = actions_array.value[ params.dataIndex ];
      let actions_list = [];
      let promises = [];

      let id = '';
      let rp_number = '';
      let rp_title = '';
      let action_title = '';
      let action_pilote = '';
      let action_delivery_date = '';
      let id_rp_redirection = '';

      var actionsByRP = new Map();
      for ( let item of list ) {
        if ( actionsByRP.has( item.id_rp ) ) {
          actionsByRP.get( item.id_rp ).push( item.id_action );
        } else {
          actionsByRP.set( item.id_rp, [ item.id_action ] );
        }
      }

      for ( var key of actionsByRP.keys() ) {
        let url = API.get_api( API.API_NAMES.RP_BY_ID, key );
        promises.push( new Promise ( ( resolve ) => AXIOS.get( url, { headers: { 'auth-token': store.getters.getToken } } )
          .then( ( response ) => {
            for ( let item of actionsByRP.get( response.data.id ) ) {
              let actions = [].concat( response.data.rp_actions ?? [] ).concat( response.data.rp_capitalisation_actions ?? [] );
              let action = actions.find( ( element ) => element.action.id === item ).action;
              id = action.id;
              rp_number = response.data.number;
              rp_title = response.data.title;
              action_title = action.title;
              action_pilote = action.action_users[ 0 ].user.fullname;
              action_delivery_date = moment( action.action_users[ 0 ].delivery_date ).locale( store.getters.getLocale ).format( 'L' );
              id_rp_redirection = response.data.id;
              actions_list.push( { id, rp_number, rp_title, action_title, action_pilote, action_delivery_date, id_rp_redirection } );
            }
            store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
            utils.showAxiosError( response );
            resolve();
          } )
          .catch( ( err ) => {
            utils.showCatch( err );
            resolve();
          } )
        ) );
      }

      Promise.all( promises )
        .then( () => {
          popupSeeActions.patchOptions( {
            attrs: {
              title: t( 'Actions' ),
              type: 'action',
              array: actions_list
            } }
          );
          popupSeeActions.open();
          emit( 'isBusy' );
        } );
    }
  }

  const popupSeeActions = useModal( {
    component: PopupDisplayArray,
    attrs: {
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      escToClose: true,
      clickToClose: true,
      onCancel() {
        popupSeeActions.close();
      }
    }
  } );
</script>