<template>
  <VueFinalModal class="flex justify-center items-center"
    content-class="popup popup-w-xl"
    :esc-to-close="escToClose" :overlay-transition="overlayTransition" :content-transition="contentTransition"
    :click-to-close="clickToClose" :lock-scroll="lockScroll">

    <!-- Close modal button -->
    <div class="relative">
      <button class="modal-close-button" @click="clickClose">
        <font-awesome-icon icon="fa-solid fa-xmark" size="2xl"/>
      </button>
    </div>

    <div class="flex-col space-y-6">
      <!-- Modal header -->
      <h1 class="text-2xl/5 font-bold text-center">{{ t( 'Report customization') }}</h1>

      <div class="flex space-x-4">
        <!-- Sections scroll list -->
        <div v-if="desiredSections.length > 0" class="w-full space-y-3">
          <h1 class="text-2xl/5 font-bold text-center">{{ t( 'Sections') }}</h1>

          <div class="w-fit">
            <LoadingButton ref="sectionsBtn" :label="t( 'Toggle all' )"
              @click="toggleStates[ 0 ] = !toggleStates[ 0 ]; toggleList( desiredSections, toggleStates[ 0 ] )"/>
          </div>

          <div class="max-h-[400px] border border-2 border-gray-400 rounded overflow-auto">
            <div v-for="section in desiredSections" :key="section"
              class="flex items-center pl-2 space-x-6 py-2 hover:bg-fourth-hover duration-200 border border-$c-divider border-gray-300 cursor-pointer font-bold"
              @click="toggleSection( section )">
              <input :id="section.id" type="checkbox" :checked="section.desired"
                class="w-6 h-6 accent-green-600/95 rounded cursor-pointer">
              <span>{{ t( section.label ) }}</span>
            </div>
          </div>
        </div>

        <!-- Tools scroll list -->
        <div v-if="desiredTools.length > 0" class="w-full space-y-3">
          <h1 class="text-2xl/5 font-bold text-center">{{ t( 'Tools') }}</h1>

          <div class="w-fit">
            <LoadingButton ref="toolsBtn" :label="t( 'Toggle all' )"
              @click="toggleStates[ 1 ] = !toggleStates[ 1 ]; toggleList( desiredTools, toggleStates[ 1 ] )"/>
          </div>

          <div class="max-h-[400px] h-fit border border-2 border-gray-400 rounded overflow-auto w-full">
            <div v-for="section in desiredTools" :key="section"
              class="flex items-center pl-2 space-x-6 py-2 hover:bg-fourth-hover duration-200 border border-$c-divider border-gray-300 cursor-pointer font-bold"
              @click="toggleSection( section )">
              <input :id="section.id" type="checkbox" :checked="section.desired"
                class="w-6 h-6 accent-green-600/95 rounded cursor-pointer">
              <span>{{ t( section.label ) }}</span>
            </div>
          </div>
        </div>

        <!-- Graphs scroll list -->
        <div v-if="desiredGraphs.length > 0" class="w-full space-y-3">
          <h1 class="text-2xl/5 font-bold text-center">{{ t( 'Indicator') }}</h1>

          <div class="w-fit">
            <LoadingButton ref="graphsBtn" :label="t( 'Toggle all' )"
              @click="toggleStates[ 2 ] = !toggleStates[ 2 ]; toggleList( desiredGraphs, toggleStates[ 2 ] )"/>
          </div>

          <div class="max-h-[400px] h-fit border border-2 border-gray-400 rounded overflow-auto w-full">
            <div v-for="section in desiredGraphs" :key="section"
              class="flex items-center pl-2 space-x-6 py-2 hover:bg-fourth-hover duration-200 border border-$c-divider border-gray-300 cursor-pointer font-bold"
              @click="toggleSection( section )">
              <input :id="section.id" type="checkbox" :checked="section.desired"
                class="w-6 h-6 accent-green-600/95 rounded cursor-pointer">
              <span>{{ t( section.label ) }}</span>
            </div>
          </div>
        </div>

        <!-- Documents scroll list -->
        <div v-if="rpDocumentsList.length > 0" class="w-full space-y-3">
          <h1 class="has-tooltip text-2xl/5 font-bold text-center">{{ t( 'Documents') }}
            <font-awesome-icon class="pl-1" icon="fa-solid fa-circle-info" style="color: #2563eb;" size="xs"/>
            <span class='tooltip my-8'>{{ t( 'Documents allowed tooltip' ) + '\n' + t( 'Allowed extensions' ) + ' ' + ALLOWED_DOCUMENTS_EXTENSIONS.toString() }}</span>
          </h1>

          <div class="w-fit">
            <LoadingButton ref="documentsBtn" :label="t( 'Toggle all' )"
              @click="toggleStates[ 3 ] = !toggleStates[ 3 ]; toggleDocumentList( filterAllowedDocuments( rpDocumentsList ), toggleStates[ 3 ] )"/>
          </div>

          <div class="max-h-[400px] h-fit border border-2 border-gray-400 rounded overflow-auto w-full">
            <div v-for="document in filterAllowedDocuments( rpDocumentsList )" :key="document"
              class="flex items-center px-2 space-x-6 py-2 hover:bg-fourth-hover duration-200 border border-$c-divider border-gray-300 cursor-pointer font-bold"
              @click.stop="toggleDocument( document )">
              <input :id="document.id" type="checkbox" :checked="isDocumentToggled( document )" @click.stop="toggleDocument( document )"
                class="w-6 h-6 accent-green-600/95 rounded cursor-pointer">
              <FileItem @click.stop="toggleDocument( document )" :file="document"/>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal footer-->
      <div class="flex space-x-4 justify-end">
        <div><LoadingButton type="submit" :label="t( 'Download' )" @click="download"/></div>
        <div><LoadingButton type="submit" :label="t( 'Cancel' )" @click="clickClose"/></div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { VueFinalModal } from 'vue-final-modal';
  import LoadingButton from '@/components/buttons/LoadingButton.vue';
  import FileItem from '@/components/file/FileItem.vue';
  import path from 'path-browserify';

  defineProps( {
    escToClose: { Boolean, required: false, default: true },
    clickToClose: { Boolean, required: false, default: true },
    lockScroll: { Boolean, required: false, default: true },
    overlayTransition: { String, required: false },
    contentTransition: { String, required: false },
    rpDocumentsList: { Object, required: true }
  } );
  const emit = defineEmits( [ 'download', 'cancel' ] );

  const { t } = useI18n();
  const ALLOWED_DOCUMENTS_EXTENSIONS = ref( [ '.png', '.jpg', '.jpeg', '.pdf' ] );

  const desiredSections = ref( [
    { id: 'description', desired: true, label: 'Description' },
    { id: 'impact_studies', desired: true, label: 'Impact Studies' },
    { id: 'securing', desired: true, label: 'Securisation' },
    { id: 'causes', desired: true, label: 'Causes' },
    { id: '_7m', desired: false, label: 'Ishikawa' },
    { id: 'action_plan', desired: true, label: 'Actions' },
    { id: 'capitalization', desired: true, label: 'Capitalisation' },
    { id: 'notes', desired: false, label: 'Notes' }
  ] );
  const desiredTools = ref( [
    { id: 'context', desired: false, label: 'Tools-Context' },
    { id: '_6w', desired: false, label: 'Tools-6W' },
    { id: 'is_is_not', desired: false, label: 'Tools-IsIsNot-method' },
    { id: '_5why', desired: false, label: 'Tool 5 why' },
    { id: '_priority_matrix', desired: false, label: 'Tool-priority-matrix' }
  ] );
  const desiredGraphs = ref( [
    { id: 'progress', desired: false, label: 'PR progress' },
    { id: 'securing', desired: false, label: 'Reactivity' },
    { id: 'causes', desired: false, label: 'Pareto of probable causes' },
    { id: 'actions', desired: false, label: 'Actions distribution' },
    { id: 'priority_matrix', desired: false, label: 'Pareto priority matrix' }
  ] );
  const desiredDocuments = ref( [] );

  const toggleStates = ref( [ false, false, false, false ] );
  const sectionsBtn = ref( null );
  const toolsBtn = ref( null );
  const graphsBtn = ref( null );
  const documentsBtn = ref( null );

  const stopLoadingButtons = () => {
    documentsBtn.value != null ? documentsBtn.value.stopLoading() : undefined;
    sectionsBtn.value != null ? sectionsBtn.value.stopLoading() : undefined;
    toolsBtn.value != null ? toolsBtn.value.stopLoading() : undefined;
    graphsBtn.value != null ? graphsBtn.value.stopLoading() : undefined;
  };

  const filterAllowedDocuments = ( list ) => {
    return list != null && Array.isArray( list )
      ? list.filter( ( document ) =>
        document != null
        && document.id != null
        && document.filename != null
        && ALLOWED_DOCUMENTS_EXTENSIONS.value.includes( path.extname( document.filename ) ) )
      : [];
  };

  const toggleSection = ( section ) => {
    if ( section != null && section.id != null && section.desired != null ) {
      section.desired = !section.desired;
    }
  };

  const isDocumentToggled = ( document ) => {
    return document != null
      && document.id != null
      && desiredDocuments.value.includes( document.id );
  };

  const toggleDocument = ( document ) => {
    if ( document != null && document.id != null ) {
      if ( !isDocumentToggled( document ) ) {
        desiredDocuments.value.push( document.id );
      } else {
        let index = desiredDocuments.value.findIndex( ( id ) => {
          return id === document.id;
        } );
        index > -1 ? desiredDocuments.value.splice( index, 1 ) : undefined;
      }
    }
  };

  const toggleList = ( list, state ) => {
    if ( state != null && list != null && Array.isArray( list ) ) {
      list.forEach( ( item ) => {
        if ( item != null ) {
          item.desired != null ? item.desired = state : undefined;
        }
      } );
    }
    stopLoadingButtons();
  };

  const toggleDocumentList = ( list, state ) => {
    if ( state != null && list != null && Array.isArray( list ) ) {
      let newList = [];
      if ( state == true ) {
        list.forEach( ( item ) => {
          item != null && item.id != null ? newList.push( item.id ) : undefined;
        } );
      }
      desiredDocuments.value = newList;
    }
    stopLoadingButtons();
  };

  const download = () => {
    let desired_sections = {};
    desiredSections.value.forEach( ( section ) => {
      section != null && section.id != null && section.desired != null
        ? desired_sections[ section.id ] = section.desired
        : undefined;
    } );

    let desired_tools = {};
    desiredTools.value.forEach( ( section ) => {
      section != null && section.id != null && section.desired != null
        ? desired_tools[ section.id ] = section.desired
        : undefined;
    } );

    let desired_graphs = {};
    desiredGraphs.value.forEach( ( section ) => {
      section != null && section.id != null && section.desired != null
        ? desired_graphs[ section.id ] = section.desired
        : undefined;
    } );

    desired_sections[ 'tools' ] = desired_tools;
    desired_sections[ 'graphs' ] = desired_graphs;
    desired_sections[ 'documents' ] = desiredDocuments.value;

    emit( 'download', desired_sections );
  };

  const clickClose = () => {
    emit( 'cancel' );
  };
</script>