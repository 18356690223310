<template>
  <div>
    <div class="text-sm bg-white shadow shadow-shadowColor rounded group mb-4">
      <div class="focus-visible:outline-none">
        <div @click="toggleExpander"
          class="flex flex-row content-center pl-2.5 pr-4 pt-2 pb-1 -mb-1 text-lg rounded hover:bg-tertiary-hover transition duration-200">
          <div class="pr-2 -mt-1">
            <img src="@/../public/img/definition_problem_logo.png" class="h-9 w-18">
          </div>
          <div class="font-bold">{{ t( 'Description of problem' ) }}</div>
          <div class="flex flex-row grow justify-end">
            <font-awesome-icon v-if="isToggled" icon="fa-solid fa-chevron-right" rotation="90" size="xl"/>
            <font-awesome-icon v-else icon="fa-solid fa-chevron-right" size="xl"/>
          </div>
        </div>
      </div>
      <Collapse :when="isToggled" class="collapse-class w-full">
        <div class="flex flex justify-between px-4 pt-2">
          <!-- Recurring problem checkbox -->
          <div v-if="rp.is_recurrent != null" class="flex items-center space-x-3">
            <input type="checkbox"
              :checked="isChecked()"
              id="recurringCheckbox"
              @change="changeRecurrentState"
              class="w-5 h-5 accent-green-600/95 rounded"
              :class="!readonly ? 'cursor-pointer' : ''"
              :disabled="readonly || checkboxDisabled"
              :key="componentKey">
            <label :for="!readonly ? 'recurringCheckbox' : ''"
              :class="!readonly ? 'cursor-pointer' : ''">
              {{ t( 'Recurrent problem' ) }}
            </label>
          </div>

          <!-- Tools buttons -->
          <div>
            <!-- Is/Is not tool button -->
            <button @click="showIsIsnotModal = true"
              class="has-tooltip-4 float-right px-1.5 rounded font-bold text-blue-500 py-1 hover:bg-tertiary-hover">
              {{ t( 'Tools-IsIsNot-method' ) }}
              <span class='tooltip'>{{ t( 'Tools-IsIsNot tooltip' ) }}</span>
            </button>

            <!-- Context tool button -->
            <button @click="showContextModal = true"
              class="has-tooltip-4 float-right px-1.5 rounded font-bold text-blue-500 py-1 hover:bg-tertiary-hover">
              {{ t( 'Tools-Context' ) }}
              <span class='tooltip'>{{ t( 'Tools-Context tooltip' ) }}</span>
            </button>

            <!-- Tools-6W button -->
            <button @click="show6WModal = true"
              class="has-tooltip-4 float-right px-1.5 rounded font-bold text-blue-500 py-1 hover:bg-tertiary-hover">
              {{ t( 'Tools-6W' ) }}
              <span class='tooltip'>{{ t( 'Tools-6W tooltip' ) }}</span>
            </button>
          </div>

        </div>
        <div class="text-base px-4 py-3">
          <FieldArea :field="description" :placeholder="t( 'Description PLACEHOLDER' )"
            :readonly="readonly" @change="applyDescription2" :key="componentKey"
            :maxlength="MAX_INPUT_LENGTH.RP_DESCRIPTION"/>
        </div>
      </Collapse>
    </div>
    <div>
      <VueFinalModal v-model="showIsIsnotModal"
        class="flex justify-center items-center"
        :click-to-close="true"
        :esc-to-close="true"
        :overlay-transition="'vfm-fade'"
        :content-transition="'vfm-fade'"
        :lock-scroll="true">
        <ToolIsIsNotModal :id_rp="rp.id" :isIsNotData="isIsNot" :readonly="readonly"
          @click-close="closeIsIsNotDescription"
          @changeIsIsNotItem="changeIsIsNotItem"
          @removeIsIsNotItem="removeIsIsNotItem"
          @changeIsIsNotList="changeIsIsNotList"/>
      </VueFinalModal>
      <VueFinalModal v-model="showContextModal"
        class="flex justify-center items-center"
        :click-to-close="true"
        :esc-to-close="true"
        :overlay-transition="'vfm-fade'"
        :content-transition="'vfm-fade'"
        :lock-scroll="true">
        <ToolContextModal :context="context"
          :id_rp="rp.id"
          :title="rp.title"
          :readonly="readonly"
          @click-close="closeContextDescription"
          @update="updateContext"/>
      </VueFinalModal>
      <VueFinalModal v-model="show6WModal"
        class="flex justify-center items-center"
        :click-to-close="true"
        :esc-to-close="true"
        :overlay-transition="'vfm-fade'"
        :content-transition="'vfm-fade'"
        :lock-scroll="true">
        <Tool6WModal :tool_6w="tool6w"
          :id_rp="rp.id"
          :title="rp.title"
          :readonly="readonly"
          @click-close="close6wDescription"
          @click-apply="applyDescription"
          @update="update6W"/>
      </VueFinalModal>
    </div>
  </div>
</template>

<script setup>
  import { ref, watch, watchEffect } from 'vue';
  import { useI18n } from 'vue-i18n';
  import utils from '@/features/utils.js';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import Tool6WModal from '@/components/modals/tools_modals/_6WToolModal.vue';
  import ToolContextModal from '@/components/modals/tools_modals/_ContextToolModal.vue';
  import ToolIsIsNotModal from '@/components/modals/tools_modals/_IsIsNotModal.vue';
  import { VueFinalModal } from 'vue-final-modal';
  import API from '@/constants/api.constants';
  import FieldArea from '@/components/object/FieldArea.vue';
  import { RP_FIELD } from '@/constants/rp.constants.js';
  import MAX_INPUT_LENGTH from '@/constants/inputLength.constants.js';

  const props = defineProps( {
    rp: { Object, required: true },
    readonly: { Boolean, required: false, default: false }
  } );
  const emit = defineEmits(
    [ 'changeItem', 'update', 'changeIsIsNotList', 'changeIsIsNotItem', 'removeIsIsNotItem' ]
  );

  const { t } = useI18n();
  const editable = ref ( false );
  const checkboxDisabled = ref( false );
  const description = ref( '' );
  const tool6w = ref( null );
  const context = ref( null );

  const isIsNot = ref ( null );
  const show6WModal = ref( false );
  const showContextModal = ref( false );
  const showIsIsnotModal = ref( false );
  const isToggled = ref( store.getters.getExpanders.description ?? true );

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };

  watchEffect( () => ( description.value = props.rp.description ) );
  watchEffect( () => ( tool6w.value = props.rp.tool_6w ) );
  watchEffect( () => ( context.value = props.rp.tool_context ) );
  watchEffect( () => ( isIsNot.value = props.rp.tool_is_is_not ) );

  watch( () => props.rp.description, () => {
    description.value = props.rp.description;
  } );

  const isChecked = () => {
    return props.rp.is_recurrent != null &&
      (
        props.rp.is_recurrent === 1 ||
        props.rp.is_recurrent === true
      );
  };

  const close6wDescription  = () => {
    show6WModal.value = false;
  };

  const closeContextDescription  = () => {
    showContextModal.value = false;
  };

  const closeIsIsNotDescription = () => {
    showIsIsnotModal.value = false;
  };

  const applyDescription  = ( desc ) => {
    description.value = desc;
    changeDescription( true );
  };

  const applyDescription2  = ( fkey, desc ) => {
    if ( fkey || !fkey )
      description.value = desc;
    changeDescription();
  };

  const changeItem = ( ...fields ) => {
    emit( 'changeItem', fields );
  };

  const updateContext = ( params ) => {
    if ( params )
      context.value = params;
    emit( 'update' );
  };

  const update6W = ( params ) => {
    if ( params )
      tool6w.value = params;
    emit( 'update' );
  };

  const changeIsIsNotList = () => {
    emit( 'changeIsIsNotList', RP_FIELD.TOOL_IS_ISNOT );
  };

  const removeIsIsNotItem = ( id ) => {
    if ( id != null && id != '' ) {
      emit( 'removeIsIsNotItem', id );
    }
  };

  const changeIsIsNotItem = ( id ) => {
    if ( id != null && id != '' ) {
      emit( 'changeIsIsNotItem', id );
    }
  };

  const changeDescription = async ( with6WTool ) => {
    let url = API.get_api( API.API_NAMES.RP, props.rp.id );
    AXIOS.put( url, { description: description.value }, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
        with6WTool ? changeItem( RP_FIELD.DESCRIPTION, RP_FIELD.TOOL_6W ) : changeItem( 'changeItem', RP_FIELD.DESCRIPTION );
        editable.value = false;
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const changeRecurrentState = async () => {
    if ( props.rp.is_recurrent != null ) {
      checkboxDisabled.value = true;
      let url = API.get_api( API.API_NAMES.RP, props.rp.id );
      AXIOS.put( url, { is_recurrent: !isChecked() }, { headers: { 'auth-token': store.getters.getToken } } )
        .then( ( response ) => {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeItem( RP_FIELD.IS_RECURRENT );
          checkboxDisabled.value = false;
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          checkboxDisabled.value = false;
          forceRerender();
          utils.showCatch( err );
        } );
    }
  };

  const toggleExpander = () => {
    store.dispatch( 'setExpanders', { description: !isToggled.value } );
    isToggled.value = store.getters.getExpanders.description;
  };
</script>