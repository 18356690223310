<template>
  <div>
    <div>
      <label class="block mb-2 text-blue-500" for="email">{{ t('Enter code') }}</label>
      <input class="w-full p-2 mb-9 text-blue-700 border-b-2 border-blue-500 outline-none focus:bg-gray-100" type="text" name="code" v-model="input.code">
    </div>
    <div>
      <button class="w-full bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" type="button" v-on:click="verify()">{{ t('Verify code') }}</button>
    </div>
    <div class="mt-4 text-white grid grid-cols-2 justify-items-stretch">
      <div class="justify-self-start">
        <SelectLanguage />
      </div>
      <div class="justify-self-end">
        <a class="text-blue-400 hover:text-blue-700 text-sm" href="#" v-on:click="clickBackLink()">{{ t('Back') }}</a>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import enumDisplayComponent from '@/enum/enumDisplayComponent';
  import AXIOS from '@/features/axios.js';
  import utils from '@/features/utils.js';
  import API from '@/constants/api.constants';
  import SelectLanguage from '@/components/object/SelectLanguage.vue';

  const props = defineProps( {
    user_id: String
  } );
  const emit = defineEmits( [ 'custom-event-name' ] );

  const { t } = useI18n();
  const input = ref( { code: '' } );

  const clickBackLink = () => {
    emit( 'custom-event-name', { display: enumDisplayComponent.GET_CODE } );
  };

  const verify = () => {
    if ( input.value.code != '' ) {
      AXIOS.post( API.get_api( API.API_NAMES.AUTH_PASSWORD_VERIFY ), { id: props.user_id, code: input.value.code } )
        .then( ( response ) => {
          if ( response.status == '204' ) {
            emit( 'custom-event-name', { id: props.user_id, code: input.value.code, display: enumDisplayComponent.CHANGE_PWD } );
          }
          utils.showAxiosError( response );
        } )
        .catch( ( err ) => {
          utils.showCatch( err );
        } );
    } else {
      utils.showWarning( t( 'Please enter a valid code' ) );
    }
  };
</script>
