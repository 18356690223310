const MAX_INPUT_LENGTH = Object.freeze( {
  ACTION_TITLE: 500,
  ACTION_DESCRIPTION: 500,
  ACTION_NOTE: 500,
  CAPITALIZATION_TEMPLATE_TITLE: 500,
  CAPITALIZATION_TEMPLATE_DESCRIPTION: 500,
  CAUSE_CATEGORY: 100,
  CAUSE_DESCRIPTION: 500,
  DOCUMENT_TITLE: 50,
  DOCUMENT_DESCRIPTION: 500,
  GROUP_NAME: 100,
  GROUP_RIGHT_NAME: 36,
  IMPACT_STUDY_QUESTION: 500,
  IMPACT_STUDY_ANSWER: 150,
  IS_IS_NOT_TEMPLATE_LABEL: 100,
  IS_IS_NOT_TEMPLATE_IS_DESC: 500,
  IS_IS_NOT_TEMPLATE_IS_NOT_DESC: 500,
  IS_IS_NOT_TEMPLATE_PARTICULARITIES_DESC: 500,
  IS_IS_NOT_TEMPLATE_CHANGES_DESC: 500,
  RP_TITLE: 55,
  RP_STATE: 10,
  RP_DESCRIPTION: 1000,
  RP_NOTE: 500,
  RP_CLIENT_NAME: 100,
  RP_CLIENT_NUMBER: 30,
  SERVICE_NAME: 36,
  SETTING_KEYNAME: 50,
  SETTING_VALUE: 500,
  SITE_NAME: 100,
  TOOL_5WHY_WHY: 150,
  TOOL_6W_DESC_WHO: 30,
  TOOL_6W_DESC_WHAT: 60,
  TOOL_6W_DESC_WHERE: 30,
  TOOL_6W_DESC_HOW: 60,
  TOOL_CONTEXT: 15000,
  TOOL_PRIORITY_MATRIX_LABEL: 40,
  TOOL_PRIORITY_MATRIX_LABEL_TOOLTIP: 150,
  IS_IS_NOT_LABEL: 100,
  IS_IS_NOT_IS: 100,
  IS_IS_NOT_IS_NOT: 100,
  IS_IS_NOT_PARTICULARITIES: 100,
  IS_IS_NOT_CHANGES: 100,
  USER_EMAIL: 150,
  USER_FIRSTNAME: 50,
  USER_LASTNAME: 50,
} );

export default MAX_INPUT_LENGTH;