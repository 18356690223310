<template>
  <div class="text-sm bg-white shadow shadow-shadowColor rounded group mb-4">
    <!-- Expander Header -->
    <div class="focus-visible:outline-none rounded">
      <div @click="toggleExpander"
        class="flex flex-row content-center items-center space-x-3 px-4 py-2 -mb-1 text-lg rounded hover:bg-tertiary-hover transition duration-200">
        <div class="pr-1">
          <font-awesome-icon icon="fa-solid fa-lightbulb" size="xl"/>
        </div>
        <div class="font-bold">{{ t( 'Actions' ) }}</div>
        <button v-if="!readonly" @click.stop="addAction" :disabled="isBusy"
          class="flex flex-col has-tooltip  text-xxl rounded-full align-center justify-center"
          :class="!isBusy ? 'hover:bg-fourth-hover text-primary' : 'text-fourth-hover'">
          <font-awesome-icon icon="fa-solid fa-plus" size="xl" class="px-1 py-0"/>
          <span class='tooltip mb-8'>{{ t( 'Add an action' ) }}</span>
        </button>
        <div class="has-tooltip flex">
          <span class='tooltip'>{{ t( 'Actions total' )}}</span>
          <span class="items-center px-1.5 pb-0.5 font-bold rounded-full bg-gray-500 text-white"
            :key="componentKey">
            {{ identifiedActionsTotal }}
          </span>
        </div>
        <div class="has-tooltip flex">
          <span class='tooltip'>{{ t( 'Planned actions' )}}</span>
          <span class="items-center px-1.5 pb-0.5 font-bold rounded-full bg-purple-600 text-white"
            :key="componentKey">
            {{ plannedActionsTotal }}
          </span>
        </div>
        <div class="has-tooltip flex">
          <span class='tooltip'>{{ t( 'Completed actions' )}}</span>
          <span class="items-center px-1.5 pb-0.5 font-bold rounded-full bg-blue-500 text-white"
            :key="componentKey">
            {{ completedActionsTotal }}
          </span>
        </div>
        <div class="has-tooltip flex">
          <span class='tooltip'>{{ t( 'Effective actions' )}}</span>
          <span class="items-center px-1.5 pb-0.5 font-bold rounded-full bg-green-500 text-white"
            :key="componentKey">
            {{ efficientActionsTotal }}
          </span>
        </div>
        <SortAndFilterOptions
          :sortOptions="sortOptions"
          :sortSelected="sortOptionSelected"
          :filterOptions="filterOptions"
          :filterSelected="filterOptionSelected"
          @changeSortOption="changeSortOption"
          @changeFilterOption="changeFilterOption"/>
        <div class="flex flex-row">
          <font-awesome-icon v-if="isToggled" icon="fa-solid fa-chevron-right" rotation="90" size="xl"/>
          <font-awesome-icon v-else icon="fa-solid fa-chevron-right" size="xl" />
        </div>
      </div>
    </div>
    <!-- Expander Body -->
    <Collapse :when="isToggled" class="collapse-class">
      <div class="flex flex-wrap gap-4 p-4 w-full" :key="componentKey">
        <RPActionItem v-for="action in sortAndFilterActionsList" :key="action.id"
          :causes="causes" :users="users" :action="action" :readonly="readonly"
          class="flex-auto"
          @changeItem="changeItem" @removeItem="removeItem"/>
      </div>
    </Collapse>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import AXIOS from '@/features/axios.js';
  import store from '@/store';
  import utils from '@/features/utils.js';
  import RPActionItem from '@/components/rp/actions/RPActionItem.vue';
  import API from '@/constants/api.constants';
  import SORT from '@/constants/sort.constants';
  import FILTER from '@/constants/filter.constants';
  import SortAndFilterOptions from '@/components/object/SortAndFilterOptions.vue';

  const props = defineProps( {
    id_rp: { String, required: true },
    readonly: { Boolean, required: false, default: false },
    actions: { Array, required: true },
    users: { Array, required: true, default: null },
    causes: { Array, required: true, default: null }
  } );
  const emit = defineEmits( [ 'changeList', 'changeItem', 'removeItem' ] );

  const { t } = useI18n();
  const isToggled = ref( store.getters.getExpanders.actions ?? true );

  const componentKey = ref ( 0 );
  const forceRerender = () => {
    componentKey.value += 1;
  };
  const isBusy = ref( false );

  const sortOptionSelected = ref( store.getters.getActionsOptions.sort ?? SORT.REGISTER_DATE_DESC );
  const sortOptions = ref( [
    SORT.REGISTER_DATE_ASC,
    SORT.REGISTER_DATE_DESC,
    SORT.LAST_ACTIVITY_ASC,
    SORT.LAST_ACTIVITY_DESC,
    SORT.TITLE_DESC,
    SORT.TITLE_ASC
  ].map( ( item ) => {
    return { val: item, text: t( item ) };
  } ) );
  const filterOptionSelected = ref( store.getters.getActionsOptions.filter ?? FILTER.ALL );
  const filterOptions = ref( [
    FILTER.ALL,
    FILTER.WITH_PILOT,
    FILTER.WITHOUT_PILOT,
    FILTER.IDENTIFIED,
    FILTER.PLANNED,
    FILTER.COMPLETED,
    FILTER.EFFICIENT
  ].map( ( item ) => {
    return { val: item, text: t( item ) };
  } ) );
  const sortAndFilterActionsList = computed( () => {
    return utils.sortActions( sortOptionSelected.value, utils.filterActions( filterOptionSelected.value, props.actions ) );
  } );

  const identifiedActionsTotal = computed( () => {
    return props.actions != null && Array.isArray( props.actions )
      ? props.actions.length : 0;
  } );

  const plannedActionsTotal = computed( () => {
    return props.actions != null && Array.isArray( props.actions )
      ? props.actions.filter( ( item ) => utils.isPlannedAction( item ) ).length
      : 0;
  } );

  const completedActionsTotal = computed( () => {
    return props.actions != null && Array.isArray( props.actions )
      ? props.actions.filter( ( item ) => utils.isCompletedAction( item ) ).length
      : 0;
  } );

  const efficientActionsTotal = computed( () => {
    return props.actions != null && Array.isArray( props.actions )
      ? props.actions.filter( ( item ) => utils.isEfficientAction( item ) ).length
      : 0;
  } );

  const changeSortOption = ( newOption ) => {
    sortOptionSelected.value = newOption;
    store.dispatch( 'setActions', { sort: newOption } );
  };

  const changeFilterOption = ( newOption ) => {
    filterOptionSelected.value = newOption;
    store.dispatch( 'setActions', { filter: newOption } );
  };

  const changeList = () => {
    emit( 'changeList' );
  };

  const changeItem = ( id ) => {
    emit( 'changeItem', id );
  };

  const removeItem = ( id ) => {
    emit( 'removeItem', id );
  };

  const addAction = () => {
    let url = API.get_api( API.API_NAMES.RP_ACTIONS, props.id_rp );
    let body = {
      title: t( 'Title PLACEHOLDER' )
    };
    isBusy.value = true;

    AXIOS.post( url, body, { headers: { 'auth-token': store.getters.getToken } } )
      .then( ( response ) => {
        utils.analyse_axios_request( response );
        if ( response.status == '200' ) {
          store.dispatch( 'updateToken', { token: response.headers[ 'auth-token' ] } );
          changeList();
        } else {
          forceRerender();
        }
        isBusy.value = false;
        utils.showAxiosError( response );
      } )
      .catch( ( err ) => {
        isBusy.value = false;
        forceRerender();
        utils.showCatch( err );
      } );
  };

  const toggleExpander = () => {
    store.dispatch( 'setExpanders', { actions: !isToggled.value } );
    isToggled.value = store.getters.getExpanders.actions;
  };

  defineExpose( { toggleExpander, isToggled } );
</script>